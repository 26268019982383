import React from 'react'

import styles from 'app/components/categorySection/CategorySection.module.scss'
import { WppTypography } from 'app/components/common'
import HelpSection from 'app/components/common/helpSection'
import FormFieldService from 'services/formField/FormFieldService'
import IField from 'types/field/IField'
import { isEqual } from 'utils/lodash'

interface IFormFieldReadOnlyProps {
  field: IField
  fieldType: string
}

/**
 * Create empty form field
 * @param {object} props
 * @param {IField} props.field
 * @param {string} props.fieldType
 */
const FormFieldReadOnly: React.FC<IFormFieldReadOnlyProps> = ({
  field,
  fieldType,
}: IFormFieldReadOnlyProps): React.ReactElement => {
  const { id, value, fieldConfig } = field
  const { options } = fieldConfig
  const formFieldService = new FormFieldService()

  const fieldValue = formFieldService.getReadOnlyFieldValue(value, options, fieldType)

  return (
    <>
      <WppTypography
        type="s-body"
        aria-disabled="true"
        className={isEqual(fieldValue.length, 0) ? styles.formReadOnlyEmpty : styles.formReadOnlyGeneral}
        data-testid={id ?? `readonly-${field.id}`}
        id={id ?? `readonly-${field.id}`}
      >
        {fieldValue}
      </WppTypography>
      <HelpSection field={field} value={field.value} />
    </>
  )
}

export default FormFieldReadOnly
