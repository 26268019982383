import { TFunction } from 'i18next'

import ICategory from 'types/category/ICategory'
import IChangeLogData from 'types/changeLog/IChangeLogData'
import ITableHeader from 'types/table/ITableHeader'
import ITableRow from 'types/table/ITableRow'

export const getColumnData = (t: TFunction): ITableHeader[] => {
  return [
    {
      id: 'id',
      name: t('change_log.log_list.column_data.date'),
      width: 200,
      enableOrderBy: true,
      hidden: true,
    },
    {
      id: 'date',
      name: t('change_log.log_list.column_data.date'),
      width: 200,
      enableOrderBy: true,
    },
    {
      id: 'categoryName',
      name: t('change_log.log_list.column_data.category_name'),
      width: 150,
    },
    {
      id: 'fieldName',
      name: t('change_log.log_list.column_data.field_name'),
      width: 150,
    },
    {
      id: 'oldValue',
      name: t('change_log.log_list.column_data.old_value'),
      minWidth: 150,
    },
    {
      id: 'newValue',
      name: t('change_log.log_list.column_data.new_value'),
      minWidth: 150,
    },
    {
      id: 'userEmail',
      name: t('change_log.log_list.column_data.user_email'),
      width: 140,
    },
  ]
}

export const getRowData = (categories: Array<ICategory>, changeLogData: IChangeLogData[]): ITableRow[] => {
  const tableRows: ITableRow[] = changeLogData.map(
    ({ categoryName, date, fieldName, newValue, oldValue, userEmail, id }: IChangeLogData) => {
      return {
        id: {
          text: id,
        },
        date: {
          text: date,
        },
        categoryName: {
          text: categoryName,
        },
        fieldName: {
          text: fieldName,
        },
        newValue: {
          text: newValue,
        },
        oldValue: {
          text: oldValue,
        },
        userEmail: {
          text: userEmail,
        },
      }
    },
  )
  return tableRows
}
