// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.byzf5{display:flex;align-items:flex-start;justify-content:space-between}.byzf5 .URpvr{margin-top:4px}.byzf5 .URpvr::part(message){color:var(--wpp-text-color-info) !important}.byzf5 .rWT4U{margin-top:4px}.byzf5 .rWT4U::part(message){color:var(--wpp-text-color-danger)}`, "",{"version":3,"sources":["webpack://./src/app/components/common/helpSection/HelpSection.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,sBAAA,CACA,6BAAA,CAEA,cACE,cAAA,CACA,6BACE,2CAAA,CAGJ,cACE,cAAA,CACA,6BACE,kCAAA","sourcesContent":[".helpSection {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n\n  .inlineHelpMessage {\n    margin-top: 4px;\n    &::part(message) {\n      color: var(--wpp-text-color-info) !important;\n    }\n  }\n  .inlineErrorMessage {\n    margin-top: 4px;\n    &::part(message) {\n      color: var(--wpp-text-color-danger);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpSection": `byzf5`,
	"inlineHelpMessage": `URpvr`,
	"inlineErrorMessage": `rWT4U`
};
export default ___CSS_LOADER_EXPORT___;
