import { OsContext } from '@wpp-open/core'
import { AxiosResponse } from 'axios'
import moment from 'moment'

import { getMarketsAPIPath } from 'constants/apiPaths'
import { DATE_TIME_FORMAT } from 'constants/field'
import AxiosService from 'lib/AxiosService'
import PermissionService from 'services/permission/PermissionService'
import IAppContextState from 'store/interfaces/IAppContextState'
import IMarket from 'types/common/IMarket'
import IOption from 'types/common/IOption'
import { CURRENCY_TYPES } from 'types/enum'
import { FIELD_TYPES } from 'types/field/enum'
import IResponseField from 'types/field/response/IResponseField'
import { isArray, isEmpty, isEqual, isNull, sortBy, toString } from 'utils/lodash'

export default class CommonService {
  /**
   * Return application id, instance id and name
   * @param {OsContext} osContext
   * @returns {{ appInstanceId: string; appName: string; appId: string}}
   */
  public static getAppDetails(osContext: OsContext): {
    appInstanceId: string
    appName: string
    appId: string
  } {
    const { appInstance } = osContext
    const appContext: { appName: string; appId: string } = appInstance?.context as { appName: string; appId: string }
    let appName = appInstance?.name ?? ''
    let appId = appContext ? appContext.appId : ''

    if (appContext?.appName) {
      appName = appContext.appName
    }

    return {
      appInstanceId: appInstance?.id ?? '52cab828-4cd6-4f08-b98c-64d4bfe53531',
      appName,
      appId,
    }
  }
  /**
   * Get app state
   * @param {OsContext} osContext
   * @returns {IAppContextState}
   */
  public static getAppState(osContext: OsContext): IAppContextState {
    let permissions = PermissionService.getPermissions(osContext.permissions)
    let tenantLogo = osContext.tenant.logoThumbnail?.url

    const { tenant, project, userDetails } = osContext
    const isAppEditable = PermissionService.hasAppEditor(permissions)
    const { appInstanceId, appName, appId } = this.getAppDetails(osContext)

    // For Local development
    let appConfiguration: IAppContextState = {
      tenantId: tenant.id,
      itemId: project?.itemId ?? '',
      projectId: project?.id ?? '',
      projectName: project?.name ?? '',
      userEmail: userDetails.email,
      homeUrl: tenant.homeUrl,
      appInstanceId,
      appName,
      appId,
      loading: false,
      permissions,
      tenantLogo,
      isAppEditable,
    }

    if (isEqual(process.env.NODE_ENV, 'development') && isNull(project)) {
      appConfiguration = {
        tenantId: tenant.id,
        projectId: 'e9869117-35f5-41df-a5fd-71ba61d0946c',
        itemId: 'cb2d1c43-747d-4579-8acc-d623efd3ee65',
        appId,
        projectName: 'Test_Project',
        userEmail: userDetails.email,
        homeUrl: tenant.homeUrl,
        appInstanceId,
        appName,
        loading: false,
        permissions,
        tenantLogo,
        isAppEditable,
      }
    }
    return appConfiguration
  }

  /**
   * Build Option Array
   * @param {T[]} array
   * @param {string} idKey
   * @param {string} labelKey
   * @param {string} parentIdKey
   * @returns {IOption[]}
   */
  public static getOptions<T>(array: T[], idKey: string, labelKey: string, parentIdKey: string = ''): IOption[] {
    return array.map((data: any) => ({
      id: data[idKey],
      label: data[labelKey],
      parentId: data[parentIdKey],
    }))
  }

  /**
   * Get Currency List
   * @returns {IOption[]}
   */
  getCurrencyList = (): IOption[] => {
    const list: IOption[] = []
    for (let key in CURRENCY_TYPES) {
      const label: string = `${key} - ${CURRENCY_TYPES[key as keyof typeof CURRENCY_TYPES]}`
      list.push({
        id: label,
        label,
      })
    }
    return list
  }

  /**
   * Get label
   * @param {string[]} input
   * @param {any} inputResponse
   * @returns {string}
   */
  getLabel = (input: string[], inputResponse: any): string => {
    let label = ''

    input.forEach((data: string) => {
      label = isEmpty(label) ? inputResponse[data] : `${label} ${inputResponse[data]}`
    })

    return label
  }

  /**
   * Get select options
   * @param {IResponseField} field
   * @param {string} accessToken
   * @param {string} tenantId
   * @returns {Promise<IOption[]>}
   */
  getSelectOptions = async (field: IResponseField, accessToken: string, tenantId: string): Promise<IOption[]> => {
    const { options, apiUrl } = field
    if (!isEmpty(options)) return options
    const axiosService = new AxiosService(accessToken)

    if (isEqual(field.fieldType, FIELD_TYPES.CURRENCY)) {
      return this.getCurrencyList()
    }

    if (
      isEqual(field.fieldType, FIELD_TYPES.AUTOCOMPLETE) ||
      isEqual(field.fieldType, FIELD_TYPES.USER) ||
      isEqual(field.fieldType, FIELD_TYPES.USER_MENTION)
    ) {
      return options
    }

    if (isEqual(field.fieldType, FIELD_TYPES.MARKET)) {
      const response: AxiosResponse<{
        data: IMarket[]
      }> = await axiosService.get(getMarketsAPIPath(), tenantId)
      return response.data.data.map((market: IMarket) => ({ id: market.id, label: market.name }))
    }

    if (!accessToken || !apiUrl) return options ?? []

    if (!field?.config?.autocomplete) return []

    const { keys } = field?.config.autocomplete ?? {}
    const { id, label } = keys

    const Result: any = await axiosService.get(apiUrl, tenantId)
    const Response = isArray(Result.data.data) ? Result.data.data : []
    return Response.map((data: any) => {
      return {
        id: data[id],
        label: this.getLabel(label, data),
      }
    })
  }

  /**
   * Find array value inside the target value
   * @param {string[]} inputArray
   * @param {string[]} targetArray
   * @returns {boolean}
   */
  findAnyArrayValueInTargetArray = (inputArray: string[], targetArray: string[]): boolean => {
    for (let value of targetArray) {
      for (let inputValue of inputArray) {
        if (isEqual(toString(value), toString(inputValue))) {
          return true
        }
      }
    }
    return false
  }

  /**
   * Get formatted date in UTC
   * @param {string} date
   * @returns {string}
   */
  public static readonly getFormattedDateInUTC = (date: string): string => {
    if (!date) return ''
    return `${moment.utc(date).format(DATE_TIME_FORMAT)} (UTC)`
  }

  /**
   * Get formatted date in UTC
   * @param {T} inputArray
   * @returns {T[]}
   */
  public static getValidArray<T>(inputArray: T[] | null): T[] {
    if (!isArray(inputArray)) return []
    return inputArray
  }

  /**
   * Sort option data
   * @param {IOption[]} data
   * @returns {IOption[]}
   */
  public static readonly sortOptionData = (data: IOption[]): IOption[] => {
    return sortBy(data.map((option: IOption) => option.id))
  }

  /**
   * Return base64 value for the image
   * @param {string} imgUrl
   * @param {Function} callback
   * @returns {void}
   */
  public static getBase64Image(imgUrl: string, callback: Function): void {
    let img = new Image()
    img.onload = function () {
      let canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      let ctx: any = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      let dataURL = canvas.toDataURL('image/png')
      dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
      callback(dataURL)
    }
    img.onerror = function () {
      callback(null)
    }
    img.setAttribute('crossOrigin', 'anonymous') //
    img.src = imgUrl
  }
}
