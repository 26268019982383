import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OsContext } from '@wpp-open/core'

import CommonService from 'services/CommonService'
import IAppContextState from 'store/interfaces/IAppContextState'

const defaultAppContextState: IAppContextState = {
  appInstanceId: '',
  appName: '',
  homeUrl: '',
  itemId: '',
  appId: '',
  projectId: '',
  projectName: '',
  tenantId: '',
  userEmail: '',
  loading: true,
  permissions: [],
  tenantLogo: null,
  isAppEditable: false,
}

export const appContextSlice = createSlice({
  name: 'appContext',
  initialState: defaultAppContextState,
  reducers: {
    setAppContext: (state: IAppContextState, action: PayloadAction<OsContext>) => {
      return {
        ...state,
        ...CommonService.getAppState(action.payload),
        loading: false,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAppContext } = appContextSlice.actions

export default appContextSlice.reducer
