import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { WppBanner } from 'app/components/common'
import { RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { QUESTIONNAIRE_STATUS } from 'types/review/enum'
import { isEqual, isNull } from 'utils/lodash'

/**
 * RejectBanner
 * Shows rejection banner in the application
 */
const RejectBanner: React.FC = (): React.ReactElement => {
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const { app } = questionnaireState
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  return (
    <>
      {!isNull(questionnaire.approval) && isEqual(questionnaire.approval.status, QUESTIONNAIRE_STATUS.FAILED) && (
        <WppBanner id="reject-banner" type="information" show>
          <Trans
            values={{
              appName: app!.appName,
            }}
          >
            review.reject.banner.title
          </Trans>
        </WppBanner>
      )}
    </>
  )
}

export default RejectBanner
