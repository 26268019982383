import React from 'react'
import { createPortal } from 'react-dom'

import { WppSideModal } from 'app/components/common'
import styles from 'app/components/common/modals/help/Help.module.scss'
import { isEqual } from 'utils/lodash'

import 'app/components/common/modals/help/styles.scss'

interface IHelpModalProps {
  title: string
  content: string
  isModalOpen: boolean
  handleModalAction: () => void
}

/**
 * Help component
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.content
 * @param {boolean} props.isModalOpen
 * @param {() => void} props.handleModalAction
 */
const HelpModal: React.FC<IHelpModalProps> = ({
  title,
  content,
  isModalOpen,
  handleModalAction,
}: IHelpModalProps): React.ReactElement => {
  return (
    <>
      {createPortal(
        <WppSideModal
          className={styles.modal}
          onWppSideModalClose={event => {
            if (isEqual(event.detail.reason, 'escapePress')) return
            handleModalAction()
          }}
          data-testid="help-side-modal"
          open={isModalOpen}
        >
          <h3 slot="header">{title}</h3>
          <div slot="body">
            <div
              className="modalBody"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
        </WppSideModal>,
        document.body,
      )}
    </>
  )
}

export default HelpModal
