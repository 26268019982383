import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddFormField from 'app/components/categorySection/formBuilder/formField/actions/Add'
import FormFieldBuilder from 'app/components/categorySection/formBuilder/formFieldBuilder'
import FormService from 'services/form/FormService'
import FormFieldService from 'services/formField/FormFieldService'
import { AppDispatch, RootState } from 'store'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { setState } from 'store/reducers/questionnaireSlice'
import IField from 'types/field/IField'
import IFieldChangeEvent from 'types/field/IFieldChangeEvent'
import IRepeatableField from 'types/field/repeatable/IRepeatableField'
import { gt, isEmpty, isEqual, lte, orderBy } from 'utils/lodash'

interface FieldProps {
  field: IField
  isChildField: boolean
}

/**
 * Build field
 * @param {object} props
 * @param {IField} props.field
 * @param {boolean} props.isChildField
 */
const Field: React.FC<FieldProps> = (props: FieldProps): React.ReactElement => {
  const { field, isChildField } = props
  const dispatch = useDispatch<AppDispatch>()
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)

  /**
   * Handle form field change
   * @param {IFieldChangeEvent} event
   */
  const handleChange = (event: IFieldChangeEvent): void => {
    const { app } = questionnaireState
    if (!app) return
    const updatedEvent: IFieldChangeEvent = { ...event, categoryId: field.fieldConfig.categoryId }

    if (!FormFieldService.isValidChange(updatedEvent)) return

    const formService = new FormService()
    const UpdatedApp = formService.updateApp(app, updatedEvent)
    dispatch(
      setState({
        ...questionnaireState,
        appStatus: {
          ...questionnaireState.appStatus,
          isAppTouched: true,
        },
        app: UpdatedApp,
      }),
    )
  }

  if (field.isHidden) {
    return <Fragment key={field.id} />
  }

  if (lte(field.maxRepeatableAmount, 0)) {
    return (
      <div key={field.id}>
        <FormFieldBuilder handleChange={handleChange} field={field} isFirstField isChildField={isChildField} />
      </div>
    )
  }

  return (
    <>
      {orderBy(field.repeatableFields, ['order'], 'asc').map(
        ({ id, value, isValid, touched, errorMessage, isCompleted }: IRepeatableField, fieldIndex: number) => {
          let fieldTitle = field.fieldConfig.text
          if (!isEmpty(fieldTitle) && gt(field.maxRepeatableAmount, 1)) {
            fieldTitle = `${field.fieldConfig.text} ${fieldIndex + 1}`
          }
          return (
            <div key={id}>
              <FormFieldBuilder
                {...props}
                handleChange={(event: IFieldChangeEvent) =>
                  handleChange({
                    ...event,
                    repeatableFieldId: id,
                  })
                }
                field={{
                  ...field,
                  value,
                  fieldConfig: {
                    ...field.fieldConfig,
                    text: fieldTitle,
                  },
                  errorMessage,
                  messageType: !isValid && touched ? 'error' : undefined,
                  isCompleted,
                }}
                isFirstField={isEqual(fieldIndex, 0)}
                repeatableFieldId={id}
                isChildField={isChildField}
              />
              {!field.disabled &&
                gt(field.maxRepeatableAmount, 0) &&
                isEqual(fieldIndex + 1, field.repeatableFields.length) && (
                  <AddFormField field={field} handleChange={handleChange} />
                )}
            </div>
          )
        },
      )}
    </>
  )
}

export default Field
