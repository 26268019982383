import IPaginator from 'types/common/IPaginator'

export const DEFAULT_PAGINATOR_DATA: IPaginator = {
  itemsPerPage: 5,
  page: 1,
  totalItems: 0,
  totalPages: 0,
}

export const DEFAULT_TABLE_PAGINATION_ITEMS_PER_PAGE: number[] = [2, 5, 10]
export const DEFAULT_TABLE_PAGINATION_THRESHOLD: number = 5
