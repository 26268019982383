import moment from 'moment'

import IApp from 'types/app/IApp'
import ICategory from 'types/category/ICategory'
import IChangeLogFilter from 'types/changeLog/IChangeLogFilter'
import IChangeLogFilterModal from 'types/changeLog/IChangeLogFilterModal'
import { FIELD_TYPES } from 'types/field/enum'
import IField from 'types/field/IField'
import { isEmpty, isEqual, orderBy, trim } from 'utils/lodash'

export default class FilterService {
  /**
   * Get Categories
   * @param {IApp} app
   * @returns {Array<ICategory>}
   */
  public static getCategories(app: IApp): Array<ICategory> {
    let categories: Array<ICategory> = []
    app.categories.forEach((category: ICategory) => {
      categories.push(category)
    })
    return orderBy(categories, ['order'], 'asc')
  }

  /**
   * Get form field
   * @param {IField} field
   * @param {FIELD_TYPES} fieldType
   * @returns {Array<IField>}
   */
  public static getFormFields(field: IField, fieldType: FIELD_TYPES | null = null): Array<IField> {
    let fields: Array<IField> = []
    fields.push({
      ...field,
      fieldConfig: {
        ...field.fieldConfig,
        text: isEmpty(trim(field.fieldConfig.text)) ? 'EMPTY LABEL' : field.fieldConfig.text,
      },
    })
    field.children.forEach((childField: IField) => {
      fields = [...fields, ...this.getFormFields(childField)]
    })

    if (fieldType) {
      fields = fields.filter((param: IField) => isEqual(param.type, fieldType))
    }

    return fields
  }

  /**
   * Get Fields by categories
   * @param {Array<ICategory>} categories
   * @param {FIELD_TYPES} fieldType
   * @returns {Array<IField>}
   */
  public static getFieldsByCategories(
    categories: Array<ICategory>,
    fieldType: FIELD_TYPES | null = null,
  ): Array<IField> {
    let fields: Array<IField> = []

    for (let category of categories) {
      for (let form of category.forms) {
        for (let field of form.fields) {
          fields = [...fields, ...this.getFormFields(field, fieldType)]
        }
      }
    }
    return orderBy(fields, ['fieldConfig.text'], 'asc')
  }

  /**
   * Get default date range for filter
   * @returns {Array<string>}
   */
  public static getFilterDefaultDateRange(): Array<string> {
    return [moment().subtract(30, 'days').format('MM/DD/YYYY'), moment().format('MM/DD/YYYY')]
  }

  /**
   * Get Filter count
   * @param {Array<IChangeLogFilterModal>} sideModalData
   * @param {Array<IChangeLogFilter>} filterSelectedData
   * @returns {number}
   */
  public static getFilterCount(sideModalData: IChangeLogFilterModal, filterSelectedData: IChangeLogFilter): number {
    let count: number = 0
    const { categories, formFields } = sideModalData
    const { selectedCategories, selectedFormFields, selectedUserField, viewOnlyFiles } = filterSelectedData.sideModal

    if (!isEqual(categories.length, selectedCategories.length)) count += 1
    if (!isEqual(formFields.length, selectedFormFields.length)) count += 1

    count = viewOnlyFiles ? count + 1 : count
    count = isEmpty(selectedUserField.value) ? count : count + 1

    return count
  }
}
