import { WppSegmentedControl, WppSegmentedControlItem, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { WppButton, WppSideModal, WppListItem, WppTooltip } from 'app/components/common'
import { ConfirmationModal } from 'app/components/common/modals'
import Select from 'app/components/common/select'
import styles from 'app/features/review/components/sendToReview/SendToReview.module.scss'
import ReviewService from 'app/features/review/services/ReviewService'
import { REVIEW_TYPES_DATA } from 'constants/review'
import useTerms from 'hooks/useTerms'
import QuestionnaireService from 'services/questionnaire/QuestionnaireService'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import IReviewState from 'store/interfaces/IReviewState'
import { setSendToReviewModal } from 'store/reducers/reviewSlice'
import { SelectChangeEventDetail, WppSegmentedControlItemCustomEvent, WppSelectCustomEvent } from 'types/common/utils'
import IProjectMember from 'types/project/IProjectMember'
import { QUESTIONNAIRE_STATUS, REVIEW_TYPES } from 'types/review/enum'
import IReviewerInfo from 'types/review/IReviewerInfo'
import IReviewType from 'types/review/IReviewType'
import ITenantUserGroup from 'types/tenant/ITenantUserGroup'
import { find, gt, isEmpty, isEqual, isNull, isUndefined, toLower, toString } from 'utils/lodash'

/**
 * SendToReview Modal component
 * Shows send to review button and when user clicks on the button, it will open up side modal to select reviewers
 */
const SendToReview: React.FC = (): React.ReactElement => {
  const { osApi } = useOs()
  const termsConfig = useTerms()
  const selectRef = useRef<HTMLWppSelectElement>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const { selectedReviewers, isSendToReviewModalOpen, reviewers, tenantUserGroups, selectedTenantUserGroups } =
    useSelector<RootState, IReviewState>((state: RootState) => state.reviewState)
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  const [selectReviewers, setSelectReviewers] = useState<IProjectMember[]>([])
  const [confirmationModal, setConfirmationModal] = useState<{ totalNoOfReviewers: number; open: boolean }>({
    totalNoOfReviewers: 0,
    open: false,
  })

  const [reviewType, setReviewType] = useState<REVIEW_TYPES>(REVIEW_TYPES.ANY_REVIEW)
  const { questionnaire } = projectBriefState
  const { app, activeStep } = questionnaireState
  const [selectTenantUserGroups, setSelectTenantUserGroups] = useState<ITenantUserGroup[]>([])

  const Reviewers = reviewers.filter(
    (member: IProjectMember) => !isEqual(toLower(member.email), toLower(appContext.userEmail)),
  )

  useEffect(() => {
    if (isSendToReviewModalOpen) {
      setSelectReviewers(
        Reviewers.filter((member: IProjectMember) => {
          const reviewer: IReviewerInfo | undefined = selectedReviewers.find((reviewer: IReviewerInfo) => {
            return isEqual(toLower(member.email), toLower(reviewer.reviewerEmail))
          })
          return !isUndefined(reviewer)
        }),
      )

      setSelectTenantUserGroups(
        tenantUserGroups.filter((tenantUserGroup: ITenantUserGroup) => {
          const reviewerUserGroup: ITenantUserGroup | undefined = selectedTenantUserGroups.find(
            (userGroup: ITenantUserGroup) => {
              return isEqual(tenantUserGroup.id, userGroup.id)
            },
          )
          return !isUndefined(reviewerUserGroup)
        }),
      )

      if (questionnaire.approval) {
        let reviewT = find(REVIEW_TYPES_DATA, { key: questionnaire.approval.successConstraint })
        setReviewType(reviewT ? reviewT.key : REVIEW_TYPES.ANY_REVIEW)
      }
      selectRef?.current?.updateOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendToReviewModalOpen])

  // Creates options for member dropdown
  const generateMemberListItems = useCallback(
    (reviewer: IProjectMember) => (
      <WppListItem value={reviewer} key={reviewer.id}>
        <p slot="label">
          {reviewer.firstname} {reviewer.lastname}
        </p>
        <p slot="caption">{reviewer.email}</p>
      </WppListItem>
    ),
    [],
  )

  // Creates options for group dropdown
  const generateGroupListItems = useCallback(
    (userGroup: ITenantUserGroup) => (
      <WppListItem value={userGroup} key={userGroup.id}>
        <p slot="label">{userGroup.name}</p>
        <p slot="caption">
          {t('review.send_to_review.group_dropdown_subtitle', { noOfMembers: userGroup.uniqueUsersCount })}
        </p>
      </WppListItem>
    ),
    [t],
  )

  if (
    !(
      (isNull(questionnaire.approval) ||
        (questionnaire.approval && isEqual(questionnaire.approval.status, QUESTIONNAIRE_STATUS.FAILED))) &&
      app?.isAppEditor &&
      app?.isProjectMember
    )
  )
    return <></>

  /**
   * Shows/hide send to review side modal
   * @param {boolean} isOpen
   * @returns {void}
   */
  const handleModal = (isOpen: boolean): void => {
    if (validateApp()) dispatch(setSendToReviewModal(isOpen))
  }

  const sendForReview = () => {
    setConfirmationModal(prevState => ({ ...prevState, open: false }))
    ReviewService.handleSendToReview({
      accessToken: osApi.getAccessToken(),
      category: app.categories[activeStep - 1],
      approval: questionnaire.approval,
      dispatch,
      appContext,
      tenantUserGroups: selectTenantUserGroups,
      projectQuestionnaireId: toString(projectBriefState.questionnaire.projectQuestionnaireId),
      questionnaireState,
      reviewers: selectReviewers,
      reviewType,
      t,
      termsConfig,
    })
  }

  /**
   * Handles send to review action
   * @returns {void}
   */
  const handleSendToReview = (): void => {
    const totalNoOfReviewers = ReviewService.getTotalSelectedReviewers(selectReviewers, selectTenantUserGroups)
    if (gt(totalNoOfReviewers, 5)) {
      setConfirmationModal(prevState => ({ ...prevState, open: true, totalNoOfReviewers }))
      return
    }
    sendForReview()
  }

  /**
   * Validate questionnaire app
   * @returns {boolean}
   */
  const validateApp = (): boolean => {
    return QuestionnaireService.validateApp(questionnaireState, dispatch)
  }

  /**
   * Set review type
   * @param {WppSegmentedControlItemCustomEvent<any>} event
   */
  const handleReviewType = (event: WppSegmentedControlItemCustomEvent<any>) => {
    setReviewType(event.detail.value)
  }

  return (
    <>
      <WppTooltip
        text={t('review.send_to_review.single_member_tooltip')}
        component={
          <WppButton
            id="btn-send-to-review"
            disabled={isEmpty(Reviewers)}
            size="s"
            data-testid="btn-send-to-review"
            onClick={() => handleModal(true)}
          >
            {termsConfig('review_btn_send_to_review')}
          </WppButton>
        }
        showTooltip={isEmpty(Reviewers)}
      />
      {createPortal(
        <>
          <WppSideModal
            data-testid="side-modal"
            size="s"
            open={isSendToReviewModalOpen}
            onWppSideModalClose={event => {
              if (isEqual(event.detail.reason, 'escapePress')) return
              handleModal(false)
            }}
            disableOutsideClick
          >
            <h3 slot="header">{termsConfig('review_send_to_review_title')}</h3>
            <div slot="body" className={styles.sideModalBody}>
              <WppTypography type="s-body" tag="p">
                {t('review.send_to_review.sub_title')}
              </WppTypography>
              <Select
                ref={selectRef}
                items={Reviewers}
                label={termsConfig('review_send_to_review_title')}
                onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) =>
                  setSelectReviewers(event.detail.value)
                }
                placeholder={t('review.send_to_review.member_dropdown_placeholder')}
                data-testid="reviewer-change"
                value={selectReviewers}
                item={generateMemberListItems}
              />
              <Select
                ref={selectRef}
                items={tenantUserGroups}
                placeholder={t('review.send_to_review.group_dropdown_placeholder')}
                data-testid="group-change"
                label={t('review.send_to_review.group_dropdown_title')}
                onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) =>
                  setSelectTenantUserGroups(event.detail.value)
                }
                tooltip={t('review.send_to_review.group_dropdown_tooltip')}
                value={selectTenantUserGroups}
                item={generateGroupListItems}
                maximumSelectedItems={3}
              />
              <div className={styles.reviewTypeContainer}>
                <WppTypography type="s-strong" tag="p">
                  {termsConfig('review_type_title')}
                </WppTypography>
                <WppSegmentedControl className={styles.reviewTypes} value={reviewType} size="s" width="100%">
                  {REVIEW_TYPES_DATA.map((reviewT: IReviewType) => (
                    <WppSegmentedControlItem
                      onWppChangeSegmentedControlItem={handleReviewType}
                      key={reviewT.key}
                      value={reviewT.key}
                      data-testid="review-type"
                    >
                      {termsConfig(reviewT.title)}
                    </WppSegmentedControlItem>
                  ))}
                </WppSegmentedControl>
                <WppTypography type="xs-midi" tag="p">
                  {termsConfig((find(REVIEW_TYPES_DATA, { key: reviewType }) as IReviewType).description)}
                </WppTypography>
              </div>
            </div>
            <div slot="actions" className={styles.actions}>
              <WppButton data-testid="btn-cancel" variant="secondary" onClick={() => handleModal(false)}>
                <Trans>app.button.cancel</Trans>
              </WppButton>
              <WppButton
                disabled={
                  (isEmpty(selectReviewers) && isEmpty(selectTenantUserGroups)) || gt(selectTenantUserGroups.length, 3)
                }
                data-testid="btn-send-to-review-confirm"
                onClick={() => handleSendToReview()}
              >
                {termsConfig('review_btn_send_for_review')}
              </WppButton>
            </div>
          </WppSideModal>
          <ConfirmationModal
            body={termsConfig('review_approvers_selection_confirmation', {
              totalNoOfReviewers: confirmationModal.totalNoOfReviewers,
            })}
            size="m"
            handlePrimaryAction={() => sendForReview()}
            handleSecondaryAction={() => setConfirmationModal(prevState => ({ ...prevState, open: !prevState.open }))}
            isOpen={confirmationModal.open}
            title={t('review.send_to_review.confirmation_modal.title')}
            btnPrimaryText={termsConfig('review_confirmation_btn_send')}
            btnSecondaryText={t('app.button.cancel')}
          />
        </>,
        document.body,
      )}
    </>
  )
}

export default SendToReview
