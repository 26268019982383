import React, { forwardRef, LegacyRef } from 'react'

import { WppSelect, WppLabel } from 'app/components/common'
import styles from 'app/components/common/select/Select.module.scss'
import { LabelConfig, SelectChangeEventDetail, WppSelectCustomEvent } from 'types/common/utils'
import { gt, isUndefined } from 'utils/lodash'

interface ISelect {
  label: string
  placeholder: string
  items: any[]
  item: (option: any) => React.ReactElement
  onWppChange: (event: WppSelectCustomEvent<SelectChangeEventDetail>) => void
  value: any
  maximumSelectedItems?: number
  tooltip?: string
}

/**
 * Uses to show tenant groups and brief reviewers
 */
const Select = forwardRef<HTMLWppSelectElement, ISelect>(
  (
    { onWppChange, items, value, label, item, placeholder, tooltip, maximumSelectedItems }: ISelect,
    ref: LegacyRef<HTMLWppSelectElement>,
  ): React.ReactElement => {
    let labelConfig: LabelConfig = { text: label }
    labelConfig = isUndefined(tooltip) ? labelConfig : { ...labelConfig, description: tooltip, icon: 'wpp-icon-info' }

    let messageConfig = {}

    if (maximumSelectedItems && gt(value.length, maximumSelectedItems)) {
      messageConfig = {
        message: `You can select up to ${maximumSelectedItems} groups.`,
        messageType: 'error',
      }
    }

    return (
      <div className={styles.container}>
        <WppLabel typography="s-strong" config={labelConfig} htmlFor={label} />
        <WppSelect
          data-testid="reviewer-change"
          onWppChange={onWppChange}
          placeholder={placeholder}
          value={value}
          withSearch
          withFolder
          type="multiple"
          id={label}
          ref={ref}
          name={label}
          {...messageConfig}
        >
          {items.map(option => item(option))}
        </WppSelect>
      </div>
    )
  },
)

export default Select
