import { isDevelopmentEnv } from 'utils/common'

export enum API_TYPES {
  QUESTIONNAIRE_API = 'QUESTIONNAIRE_API',
  PROJECT_API = 'PROJECT_API',
  APP_NAMESPACE = 'APP_NAMESPACE',
  FACADE_API = 'FACADE_API',
  MASTER_API = 'MASTER_API',
}
const developmentApi: Record<API_TYPES, string> = {
  QUESTIONNAIRE_API: 'https://questionnaire-api-ch-preprod.os-dev.io/api',
  PROJECT_API: 'https://project-api-ch-preprod.os-dev.io/api',
  APP_NAMESPACE: 'APP_PROFILE_832b1371-30df-468d-86b5-b7cbade1bcea',
  FACADE_API: 'https://facade-api-ch-preprod.os-dev.io/api',
  MASTER_API: 'https://master-data-api-ch-preprod.os-dev.io/api',
}

const productionApi: Record<API_TYPES, string> = {
  QUESTIONNAIRE_API: 'https://questionnaire-api-ch-joker.os-dev.io/api',
  PROJECT_API: 'https://project-api-ch-joker.os-dev.io/api',
  APP_NAMESPACE: '',
  FACADE_API: 'https://facade-api-ch-joker.os-dev.io/api',
  MASTER_API: 'https://master-data-api-ch-joker.os-dev.io/api',
}

export const apiConfig = Object.keys(API_TYPES).reduce((acc, type) => {
  const api = !isDevelopmentEnv
    ? process.env[`REACT_APP_${type}`] ?? productionApi[type as API_TYPES]
    : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export const APP_EDITOR_PERMISSION = apiConfig[API_TYPES.APP_NAMESPACE]
  ? `${apiConfig[API_TYPES.APP_NAMESPACE]}:PREREQUISITES_APP_FORM_COMPLETE`
  : 'PREREQUISITES_APP_FORM_COMPLETE'
