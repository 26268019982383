import { useOs } from '@wpp-open/react'
import { t } from 'i18next'
import React, { useCallback, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'

import styles from 'app/components/categorySection/formField/formFieldAssistantTool/FormFieldAssistantTool.module.scss'
import {
  WppActionButton,
  WppButton,
  WppIconChevron,
  WppIconStudio,
  WppTooltip,
  WppTypography,
} from 'app/components/common'
import { AppDispatch } from 'store'
import { setErrorAlert } from 'store/reducers/alertSlice'
import IAiAssistantTool from 'types/field/IAiAssistantTool'
import IField from 'types/field/IField'
import { first, isEmpty, isEqual, orderBy } from 'utils/lodash'

interface IFormFieldAssistantToolProps {
  field: IField
}

/**
 * Shows Assistant Tools for the field
 * @param {object} props
 * @param {IField} props.field
 */
const FormFieldAssistantTool: React.FC<IFormFieldAssistantToolProps> = ({
  field,
}: IFormFieldAssistantToolProps): React.ReactElement => {
  const { osApi } = useOs()
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()

  // Show/hide assistant tools
  const toggleHeight = useCallback(() => {
    const element = ref.current
    if (!element) return
    setOpen((prevState: boolean) => !prevState)
    const contentHeight = element.style.height
    element.style.height = isEqual(contentHeight, '40px') ? element.scrollHeight + 'px' : '40px'
  }, [])

  if (!field.aiAssistantTools || isEmpty(field.aiAssistantTools) || field.disabled) return <></>

  const openApp = (assistantTool: IAiAssistantTool) => {
    const { name, app_url, width, height } = assistantTool
    try {
      osApi.navigation.openCompactApp({
        name,
        url: app_url,
        overlayProps: {
          id: 'app-toolbar-iframe',
          bounds: 'parent',
          default: {
            x: 250,
            y: 0,
            width,
            height,
          },
          style: {
            zIndex: 50,
          },
          enableResize: true,
          cancel: '.cancel-drag',
        },
      })
    } catch {
      dispatch(setErrorAlert(t('app.assistant_app.error')))
    }
  }

  // when there is only one assistant tool app
  if (isEqual(field.aiAssistantTools.length, 1)) {
    const assistantTool = first(field.aiAssistantTools) as IAiAssistantTool
    return (
      <WppTooltip
        component={
          <WppActionButton className={styles.btnAction} onClick={() => openApp(assistantTool)}>
            <div slot="icon-start" className={styles.btnIcon}>
              <WppIconStudio />
            </div>
            <Trans>app.button.inspire_me</Trans>
          </WppActionButton>
        }
        text={assistantTool.description}
        showTooltip={!isEmpty(assistantTool.description)}
      />
    )
  }

  // when there are multiple assistant tool app
  return (
    <div className={styles.accordionContainer} ref={ref} style={{ height: '40px' }}>
      <button
        type="button"
        data-testid="btn-assistant-tool"
        className={styles.accordionHeaderContainer}
        onClick={toggleHeight}
      >
        <WppTooltip
          text={t('app.tooltip.inspire_me')}
          component={
            <div className={styles.accordionHeader}>
              <div className={styles.btnIcon}>
                <WppIconStudio />
              </div>
              <WppTypography type="s-strong" className={styles.accordionTitle}>
                <Trans>app.button.inspire_me</Trans>
              </WppTypography>
            </div>
          }
        />
        <WppIconChevron direction={open ? 'down' : 'up'} className={styles.iconChevron} />
      </button>
      <div className={styles.accordionContent}>
        {orderBy(field.aiAssistantTools, 'order', 'asc').map((assistantTool: IAiAssistantTool) => (
          <WppTooltip
            key={assistantTool.name}
            data-testid={assistantTool.name}
            component={
              <WppButton
                className={styles.btnAction}
                onClick={() => openApp(assistantTool)}
                variant="secondary"
                size="s"
              >
                {assistantTool.name}
              </WppButton>
            }
            text={assistantTool.description}
            showTooltip={!isEmpty(assistantTool.description)}
          />
        ))}
      </div>
    </div>
  )
}

export default FormFieldAssistantTool
