import React from 'react'

import styles from 'app/components/categorySection/formBuilder/formFieldBuilder/FormFieldBuilder.module.scss'
import FormFieldAssistantTool from 'app/components/categorySection/formField/formFieldAssistantTool'
import FormFieldAutoComplete from 'app/components/categorySection/formField/formFieldAutoComplete'
import FormFieldCheckbox from 'app/components/categorySection/formField/formFieldCheckbox'
import FormFieldComment from 'app/components/categorySection/formField/formFieldComment'
import FormFieldCounter from 'app/components/categorySection/formField/formFieldCounter'
import FormFieldCurrency from 'app/components/categorySection/formField/formFieldCurrency'
import FormFieldDatePicker from 'app/components/categorySection/formField/formFieldDatePicker'
import FormFieldFileUpload from 'app/components/categorySection/formField/formFieldFileUpload'
import FormFieldLabel from 'app/components/categorySection/formField/formFieldLabel'
import FormFieldNumber from 'app/components/categorySection/formField/formFieldNumber'
import FormFieldRadio from 'app/components/categorySection/formField/formFieldRadio'
import FormFieldReadOnly from 'app/components/categorySection/formField/formFieldReadOnly/FormFieldReadOnly'
import FormFieldSelect from 'app/components/categorySection/formField/formFieldSelect'
import FormFieldTextarea from 'app/components/categorySection/formField/formFieldTextarea'
import FormFieldTextInput from 'app/components/categorySection/formField/formFieldTextInput'
import FormFieldUser from 'app/components/categorySection/formField/FormFieldUser'
import { WppGrid } from 'app/components/common'
import SelectedReviewer from 'app/features/review/components/selectedReviewer'
import { READ_ONLY_FIELDS } from 'constants/field'
import IGridColumn from 'types/common/IGridColumn'
import { FIELD_TYPES } from 'types/field/enum'
import IField from 'types/field/IField'
import IFieldChangeEvent from 'types/field/IFieldChangeEvent'
import { gt, includes, omit } from 'utils/lodash'

interface IFormFieldBuilderProps {
  field: IField
  handleChange: (event: IFieldChangeEvent) => void
  isFirstField: boolean
  isChildField?: boolean
  repeatableFieldId?: string
}

/**
 * Build form field
 * Shows field based on field type
 * @param {object} props
 * @param {IField} props.field
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 * @param {boolean} props.isFirstField
 * @param {boolean} props.isChildField
 * @param {boolean} props.repeatableFieldId
 */
const FormFieldBuilder: React.FC<IFormFieldBuilderProps> = (props: IFormFieldBuilderProps): React.ReactElement => {
  let fieldElement = <></>

  const formField: IField = { ...props.field }
  if (formField.isHidden) {
    return fieldElement
  }

  const updatedProps = omit(props, ['isChildField'])

  const { fieldConfig } = formField

  switch (formField.type) {
    // Text Input Field
    case FIELD_TYPES.TEXT_INPUT:
      fieldElement = <FormFieldTextInput {...updatedProps} field={formField} inputType="text" />
      break
    // Number Field
    case FIELD_TYPES.NUMBER:
      fieldElement = <FormFieldNumber {...updatedProps} field={formField} />
      break
    // Radio Field
    case FIELD_TYPES.RADIO:
      fieldElement = <FormFieldRadio {...updatedProps} field={formField} />
      break
    // Checkbox Field
    case FIELD_TYPES.CHECKBOX:
      fieldElement = <FormFieldCheckbox {...updatedProps} field={formField} />
      break
    // Counter Field
    case FIELD_TYPES.COUNTER:
      fieldElement = <FormFieldCounter {...updatedProps} field={formField} />
      break
    // Textarea Field
    case FIELD_TYPES.TEXT_AREA:
      fieldElement = <FormFieldTextarea {...updatedProps} field={formField} />
      break
    // Autocomplete Field
    case FIELD_TYPES.AUTOCOMPLETE:
      fieldElement = <FormFieldAutoComplete {...updatedProps} field={formField} />
      break
    // User Field
    case FIELD_TYPES.USER:
    case FIELD_TYPES.USER_MENTION:
      fieldElement = <FormFieldUser {...updatedProps} field={formField} />
      break
    // File upload Field
    case FIELD_TYPES.FILE_UPLOAD:
      fieldElement = <FormFieldFileUpload {...updatedProps} field={formField} />
      break
    // Currency Field
    case FIELD_TYPES.CURRENCY:
      fieldElement = <FormFieldCurrency {...updatedProps} field={formField} />
      break
    // Select/Market Field
    case FIELD_TYPES.SELECT:
    case FIELD_TYPES.MARKET:
      fieldElement = <FormFieldSelect {...updatedProps} field={formField} />
      break
    // DatePicker Field
    case FIELD_TYPES.DATE_PICKER:
    case FIELD_TYPES.DATE_RANGE_PICKER:
      fieldElement = <FormFieldDatePicker {...updatedProps} field={formField} />
      break
  }

  /** Read only state via read only component
   *  FIELD_TYPES.TEXT_INPUT, FIELD_TYPES.NUMBER, FIELD_TYPES.COUNTER, FIELD_TYPES.TEXT_AREA,
   * FIELD_TYPES.CURRENCY, FIELD_TYPES.SELECT, FIELD_TYPES.MARKET
   */

  /** Read only state via CSS
   * FIELD_TYPES.AUTOCOMPLETE, FIELD_TYPES.USER, FIELD_TYPES.USER_MENTION,
   * FIELD_TYPES.FILE_UPLOAD, FIELD_TYPES.DATE_PICKER, FIELD_TYPES.DATE_RANGE_PICKER
   */

  /**Read only state = disabled state (no change)
   * FIELD_TYPES.RADIO, FIELD_TYPES.CHECKBOX */

  if (formField.disabled && includes(READ_ONLY_FIELDS, formField.type)) {
    fieldElement = <FormFieldReadOnly field={formField} fieldType={formField.type} />
  }

  const gridColumns: IGridColumn = props.isChildField
    ? {
        all: 22,
      }
    : {
        xl: 12,
        xxl: 12,
        sm: 18,
        md: 16,
        all: 18,
      }

  return (
    <>
      <WppGrid container fullWidth>
        <WppGrid item {...gridColumns}>
          <FormFieldLabel {...props} field={formField} />
        </WppGrid>
      </WppGrid>
      <WppGrid container fullWidth>
        <WppGrid className={styles.itemContainer} item {...gridColumns}>
          {fieldElement}
        </WppGrid>
        <WppGrid item all={props.isChildField ? 2 : 3} className={styles.commentApproverContainer}>
          <div>{props.isFirstField && <FormFieldComment field={formField} />}</div>
          {!formField.disabled && fieldConfig.setAsReviewers && <SelectedReviewer field={formField} />}
        </WppGrid>
      </WppGrid>
      {props.isFirstField && gt(formField.aiAssistantTools?.length, 1) && (
        <WppGrid container fullWidth>
          <WppGrid item {...gridColumns}>
            <FormFieldAssistantTool field={formField} />
          </WppGrid>
        </WppGrid>
      )}
    </>
  )
}

export default FormFieldBuilder
