import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

import { getTermsConfig } from 'constants/tenantConfig'

// Custom Hook: Get text config for the application
function useTerms() {
  const os = useOs()
  const data = useMemo(
    () => getTermsConfig(os?.osContext.tenant.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const termsConfig = useCallback((key: string, variables?: { [key: string]: string | number }) => {
    let text = data[key]
    if (variables) {
      for (let key in variables) {
        const regex = new RegExp(`{{${key}}}`, 'g')
        text = text.replaceAll(regex, variables[key] as string)
      }
    }
    return text
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return termsConfig
}

export default useTerms
