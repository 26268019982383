import React from 'react'

import FormFieldAutoComplete from 'app/components/categorySection/formField/formFieldAutoComplete'
import { getUserAPIPath } from 'constants/apiPaths'
import IField from 'types/field/IField'
import IFieldChangeEvent from 'types/field/IFieldChangeEvent'

interface IFormFieldUserProps {
  field: IField
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create User type field
 * @param {object} props
 * @param {IField} props.field
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 */
const FormFieldUser: React.FC<IFormFieldUserProps> = ({
  field,
  handleChange,
}: IFormFieldUserProps): React.ReactElement => {
  return (
    <FormFieldAutoComplete
      field={{
        ...field,
        fieldConfig: {
          ...field.fieldConfig,
          apiUrl: getUserAPIPath(),
          autoComplete: {
            itemsPerPage: 20,
            keys: {
              page: 'offset',
              id: 'id',
              label: ['firstname', 'lastname'],
              subLabel: ['email'],
              displayLabel: ['email'],
              avatarUrl: 'avatarUrl',
              itemsPerPage: 'limit',
            },
            orderBy: 'asc',
          },
        },
      }}
      handleChange={handleChange}
    />
  )
}

export default FormFieldUser
