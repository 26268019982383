import { v4 as uuidv4 } from 'uuid'

import { InputMessageTypes } from 'types/common/utils'
import { FIELD_ORIENTATION, FIELD_TYPES } from 'types/field/enum'
import IField from 'types/field/IField'
import IRepeatableField from 'types/field/repeatable/IRepeatableField'
import IResponseField from 'types/field/response/IResponseField'
import { cloneDeep, gt, isArray, isEmpty, isEqual, isNull, isString, lt, lte, toString } from 'utils/lodash'

export default class FormFieldValidationService {
  /**
   * Used in validateCategory under FormValidationService class
   * @param {IField} field
   * @returns {IField}
   */
  validateField(field: IField): IField {
    if (field.isHidden || field.disabled) {
      return field
    }

    let updatedField = this.validate(field, field.value)
    let isValid = updatedField.isValid
    let errorMessage = updatedField.errorMessage

    let repeatableFields: IRepeatableField[] = []
    if (gt(field.maxRepeatableAmount, 0)) {
      isValid = true
      for (let repeatableField of field.repeatableFields) {
        let rField = this.validate(field, repeatableField.value)
        isValid = isValid && rField.isValid
        errorMessage = rField.errorMessage
        repeatableFields.push({ ...repeatableField, touched: true, isValid: rField.isValid, errorMessage })
      }
    }

    return {
      ...updatedField,
      isValid,
      messageType: !isValid ? 'error' : undefined,
      repeatableFields,
      errorMessage,
    }
  }

  /**
   * Get selection and option text
   * @param {IField} field
   * @param {any} value
   * @returns {{ optionText: string; selectionText: string }}
   */
  getOptionSelectionText(field: IField): { optionText: string; selectionText: string } {
    let optionText = isEqual(field.type, FIELD_TYPES.FILE_UPLOAD) ? 'file' : 'option'

    optionText =
      isEqual(field.type, FIELD_TYPES.USER) || isEqual(field.type, FIELD_TYPES.USER_MENTION) ? 'user' : optionText

    optionText = isEqual(field.type, FIELD_TYPES.MARKET) ? 'market' : optionText

    const selectionText = isEqual(field.type, FIELD_TYPES.FILE_UPLOAD) ? 'upload' : 'select'

    return {
      optionText,
      selectionText,
    }
  }

  /**
   * Validate value for min and max selection
   * @param {IField} field
   * @param {any} value
   * @returns {IField}
   */
  validateValueSelection(field: IField, value: any): IField {
    let fieldValue = value ?? []
    const { valueAmount } = field.fieldConfig
    if (!valueAmount) {
      return field
    }

    let errorMessage = ''
    let isValid = field.isValid

    const { optionText, selectionText } = this.getOptionSelectionText(field)

    if (gt(valueAmount.min, 0) && lt(fieldValue.length, valueAmount.min)) {
      errorMessage = `Please ${selectionText} at least ${valueAmount.min} ${optionText}`
      isValid = false
    }
    if (gt(valueAmount.max, 0) && gt(fieldValue.length, valueAmount.max)) {
      errorMessage = `Please ${selectionText} up to ${valueAmount.max}  ${optionText}`
      isValid = false
    }
    return {
      ...field,
      errorMessage,
      isValid,
    }
  }

  /**
   * Validation of the fields which can have character limits
   * @param {IField} field
   * @returns {boolean}
   */
  isCharacterLimitFieldType(field: IField): boolean {
    return (
      isEqual(field.type, FIELD_TYPES.TEXT_AREA) ||
      isEqual(field.type, FIELD_TYPES.NUMBER) ||
      isEqual(field.type, FIELD_TYPES.TEXT_INPUT) ||
      isEqual(field.type, FIELD_TYPES.CURRENCY)
    )
  }

  /**
   * Validate a field
   * @param {IField} field
   * @param {any} value
   * @returns {IField}
   */
  validate(field: IField, value: any): IField {
    let isValid = true

    let errorMessage = ''

    if (this.isCharacterLimitFieldType(field)) {
      isValid = this.validateCharacterLimit(
        field,
        isEqual(field.type, FIELD_TYPES.CURRENCY) ? value.currencyAmount : value,
      )
    }

    field = {
      ...field,
      isValid,
      errorMessage,
    }

    if (field.fieldConfig.multi && field.fieldConfig.valueAmount) {
      field = this.validateValueSelection(field, value)
      isValid = isValid && field.isValid

      errorMessage = isEmpty(errorMessage) ? field.errorMessage : errorMessage
    }

    if (field.isRequired) {
      let isEmptyField = FormFieldValidationService.isEmptyField({ ...field, value })
      isValid = isValid && !isEmptyField
      errorMessage = !isEmptyField ? field.errorMessage : 'Field is required.'
    }

    field = {
      ...field,
      isValid,
      errorMessage,
    }

    return field
  }

  /**
   * Returns valid currency value
   * @param {any} inputValue
   * @returns {any}
   */
  getValidCurrencyValue = (inputValue: any) => {
    return !inputValue
      ? {
          currencyType: null,
          currencyAmount: '',
        }
      : inputValue
  }

  /**
   * Returns valid select value
   * @param {IResponseField} field
   * @param {any} inputValue
   * @returns {Array | null}
   */
  getValidSelectValue = (field: IResponseField, inputValue: any): Array<any> | null => {
    if (field.config?.multi) {
      return isArray(inputValue) ? inputValue : []
    }
    return isArray(inputValue) ? null : inputValue
  }

  /**
   * Returns valid repeatable field value
   * @param {IResponseField} field
   * @param {any} inputValue
   */
  getValidInputFieldValue = (field: IResponseField, inputValue: any) => {
    if (field.config) {
      let value = inputValue
      if (field.config?.multi) {
        return isArray(inputValue) ? inputValue : []
      }
      return isArray(value) ? value : []
    }
    return inputValue
  }

  /**
   * Returns valid repeatable field value
   * @param {IResponseField} field
   * @param {any} inputValue
   * @returns {IRepeatableField[]}
   */
  getValidRepeatableField = (field: IResponseField, inputValue: any): IRepeatableField[] => {
    if (!!inputValue && inputValue.hasOwnProperty('repeatable')) {
      return inputValue.repeatable.map((repeatableField: IRepeatableField) => {
        const fieldValue = this.getValidValue(field, repeatableField.value)

        return {
          ...repeatableField,
          value: this.getValidValue(field, repeatableField.value),
          isValid: true,
          touched: false,
          isCompleted: !isEmpty(toString(fieldValue).trim()),
        }
      })
    }

    const fieldValue = this.getValidValue(field, inputValue)

    return [
      {
        id: uuidv4(),
        value: fieldValue,
        order: 1,
        isValid: false,
        touched: false,
        errorMessage: '',
        isCompleted: !isEmpty(toString(fieldValue).trim()),
      },
    ]
  }

  /**
   * Returns valid value based on field type
   * @param {IResponseField} field
   * @param {any} inputValue
   * @returns {any}
   */
  getValidValue(field: IResponseField, inputValue: any): any {
    let value = inputValue
    switch (field.fieldType) {
      case FIELD_TYPES.TEXT_INPUT:
      case FIELD_TYPES.NUMBER:
      case FIELD_TYPES.DATE_PICKER:
      case FIELD_TYPES.TEXT_AREA: {
        value = isString(inputValue) ? inputValue : ''
        break
      }
      case FIELD_TYPES.COUNTER: {
        value = !inputValue ? 1 : inputValue
        break
      }
      case FIELD_TYPES.DATE_RANGE_PICKER: {
        value = isArray(inputValue) ? inputValue : []
        break
      }
      case FIELD_TYPES.RADIO: {
        value = !inputValue ? null : inputValue
        break
      }
      case FIELD_TYPES.CURRENCY: {
        value = this.getValidCurrencyValue(inputValue)
        break
      }
      // Select/Market Field
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.MARKET: {
        value = this.getValidSelectValue(field, inputValue)
        break
      }
      case FIELD_TYPES.CHECKBOX:
      case FIELD_TYPES.FILE_UPLOAD:
      case FIELD_TYPES.AUTOCOMPLETE:
      case FIELD_TYPES.USER_MENTION:
      case FIELD_TYPES.USER: {
        value = this.getValidInputFieldValue(field, inputValue)
        break
      }
    }
    return value
  }

  /**
   * Validate Character limit in text input/text area
   * @param {IField} field
   * @param {any} value
   * @returns {boolean}
   */
  validateCharacterLimit(field: IField, value: any): boolean {
    const inputValue = (value ?? '').toString()
    if (isEmpty(inputValue)) {
      return true
    }
    if (!field.fieldConfig.charactersLimit) {
      return true
    }

    return lte(inputValue.length, field.fieldConfig.charactersLimit)
  }

  /**
   * Validate field configuration
   * @param {IField} field
   * @returns {IField}
   */
  validateFieldConfiguration(field: IField): IField {
    let fieldData: IField = cloneDeep(field)
    const { fieldConfig } = fieldData

    switch (field.type) {
      case FIELD_TYPES.TEXT_INPUT:
      case FIELD_TYPES.TEXT_AREA:
      case FIELD_TYPES.NUMBER:
      case FIELD_TYPES.DATE_PICKER: {
        fieldData.value = isString(fieldData.value) ? fieldData.value : ''
        break
      }
      case FIELD_TYPES.DATE_RANGE_PICKER: {
        fieldData.value = !isEmpty(fieldData.value) ? fieldData.value : []
        break
      }
      case FIELD_TYPES.CHECKBOX:
      case FIELD_TYPES.AUTOCOMPLETE:
      case FIELD_TYPES.USER_MENTION:
      case FIELD_TYPES.USER: {
        fieldData.value = isArray(fieldData.value) ? fieldData.value : []
        fieldData.fieldConfig.options = isArray(fieldConfig.options) ? fieldData.fieldConfig.options : []
        break
      }
      case FIELD_TYPES.FILE_UPLOAD: {
        fieldData.value = isArray(fieldData.value) ? fieldData.value : []
        break
      }
    }

    fieldData.fieldConfig.orientation = !fieldConfig.orientation ? FIELD_ORIENTATION.VERTICAL : fieldConfig.orientation
    if (gt(field.maxRepeatableAmount, 0)) {
      fieldData.value = field.value
    }

    return fieldData
  }

  /**
   * Validate if field value is empty
   * @param {IField} field
   * @returns {boolean}
   */
  public static isEmptyField(field: IField): boolean {
    let isFieldEmpty: boolean = false

    const Value = field.value ?? ''

    switch (field.type) {
      case FIELD_TYPES.TEXT_INPUT:
      case FIELD_TYPES.DATE_PICKER:
      case FIELD_TYPES.NUMBER:
      case FIELD_TYPES.RADIO:
      case FIELD_TYPES.COUNTER:
      case FIELD_TYPES.TEXT_AREA: {
        isFieldEmpty = isEmpty(toString(Value).trim())
        break
      }
      case FIELD_TYPES.DATE_RANGE_PICKER: {
        isFieldEmpty = isEmpty(field.value)
        break
      }
      case FIELD_TYPES.CURRENCY: {
        isFieldEmpty = isNull(Value.currencyType) || isEmpty(toString(Value.currencyAmount).trim())
        break
      }
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.MARKET: {
        if (field.fieldConfig.multi) {
          isFieldEmpty = isEmpty(Value)
        } else {
          isFieldEmpty = isEmpty(toString(Value))
        }
        break
      }
      case FIELD_TYPES.CHECKBOX:
      case FIELD_TYPES.AUTOCOMPLETE:
      case FIELD_TYPES.USER_MENTION:
      case FIELD_TYPES.USER:
      case FIELD_TYPES.FILE_UPLOAD: {
        isFieldEmpty = isArray(field.value) ? isEmpty(field.value) : false
        break
      }
    }

    return isFieldEmpty
  }

  /**
   * Validate currency field (amount and currency type)
   * Used in FormFieldCurrency Component
   * @param {IField} field
   * @returns {{currencyAmountMessageType: InputMessageTypes | undefined; currencyNameMessageType: InputMessageTypes | undefined; isError: boolean}}
   */
  public static validateCurrencyAmountType(field: IField): {
    currencyAmountMessageType: InputMessageTypes | undefined
    currencyNameMessageType: InputMessageTypes | undefined
    isError: boolean
  } {
    const fieldValue = toString(field.value.currencyAmount).trim()
    const formFieldValidationService = new FormFieldValidationService()
    const isValidCurrencyValue = field.isRequired && field.touched ? !isEmpty(fieldValue) : true
    const isValidCurrencyName = field.isRequired && field.touched ? !isNull(field.value.currencyType) : true

    let currencyAmountMessageType: InputMessageTypes | undefined = isValidCurrencyValue ? undefined : 'error'
    const currencyNameMessageType: InputMessageTypes | undefined = isValidCurrencyName ? undefined : 'error'

    if (!formFieldValidationService.validateCharacterLimit(field, fieldValue)) {
      currencyAmountMessageType = 'error'
    }

    return {
      currencyAmountMessageType,
      currencyNameMessageType,
      isError: isEqual(currencyNameMessageType, 'error') || isEqual(currencyAmountMessageType, 'error'),
    }
  }
}
