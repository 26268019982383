import { useOs } from '@wpp-open/react'
import { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { WppButton, WppIconReject, WppListItem, WppModal, WppTooltip, WppTypography } from 'app/components/common'
import styles from 'app/features/review/components/cancelReview/CancelReview.module.scss'
import ReviewHelper from 'app/features/review/services/ReviewService'
import useTerms from 'hooks/useTerms'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import IReviewState from 'store/interfaces/IReviewState'
import { PROJECT_ROLES } from 'types/project/enum'
import IProjectMember from 'types/project/IProjectMember'
import { QUESTIONNAIRE_STATUS } from 'types/review/enum'
import { isEqual, toLower, toString } from 'utils/lodash'

/**
 * Cancel Review component
 * Shows cancel review modal to the project owner or requester
 */
const CancelReview: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const termsConfig = useTerms()
  const { osApi } = useOs()

  const dispatch = useDispatch<AppDispatch>()
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const { reviewers } = useSelector<RootState, IReviewState>((state: RootState) => state.reviewState)
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  const [open, setOpen] = useState(false)

  /**
   * Checks if the user is authorized to cancel review
   * @returns {boolean}
   */
  const isAuthorized: boolean = useCallback((): boolean => {
    const member: IProjectMember | undefined = reviewers.find((member: IProjectMember) =>
      isEqual(toLower(member.email), toLower(appContext.userEmail)),
    )
    return isEqual(member?.role, PROJECT_ROLES.OWNER)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.userEmail])()

  if (!questionnaire.approval) return <></>

  if (!(isEqual(questionnaire.approval.status, QUESTIONNAIRE_STATUS.PENDING) && isAuthorized)) {
    return <></>
  }

  /**
   * Show/Hide cancel review modal
   */
  const handleModal = () => setOpen((prevState: boolean) => !prevState)

  /**
   * Cancel questionnaire review
   * @returns {void}
   */
  const handleCancelReview = (): void => {
    ReviewHelper.handleCancelReview({
      accessToken: osApi.getAccessToken(),
      dispatch,
      projectQuestionnaireId: toString(questionnaire.projectQuestionnaireId),
      approval: questionnaire.approval,
      questionnaireState,
      tenantId: appContext.tenantId,
      appName: appContext.appName,
      appInstanceId: appContext.appInstanceId,
      t,
      appContext,
      termsConfig,
    })
  }

  return (
    <>
      <WppTooltip
        text={t('review.cancel_review.unauthorized')}
        component={
          <WppListItem
            id="btn-cancel-review"
            disabled={!isAuthorized}
            data-testid="btn-cancel-review"
            onWppChangeListItem={handleModal}
          >
            <p slot="label" className={styles.menuItem}>
              <WppIconReject />
              <WppTypography tag="span" type="s-body">
                {termsConfig('review_btn_cancel_review')}
              </WppTypography>
            </p>
          </WppListItem>
        }
        showTooltip={!isAuthorized}
      />
      {createPortal(
        <WppModal open={open}>
          <h3 slot="header">{termsConfig('review_cancel_review_modal_title')}</h3>
          <p slot="body">{termsConfig('review_cancel_review_modal_body')}</p>
          <div slot="actions" className={styles.actions}>
            <WppButton size="s" id="btn-no" data-testid="btn-no" variant="secondary" onClick={handleModal}>
              <Trans>app.button.no</Trans>
            </WppButton>
            <WppButton
              variant="destructive"
              id="btn-yes"
              data-testid="btn-yes"
              size="s"
              onClick={() => {
                handleCancelReview()
                handleModal()
              }}
            >
              <Trans>app.button.yes</Trans>
            </WppButton>
          </div>
        </WppModal>,
        document.body,
      )}
    </>
  )
}

export default CancelReview
