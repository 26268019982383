import castArray from 'lodash/castArray'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import first from 'lodash/first'
import forOwn from 'lodash/forOwn'
import groupBy from 'lodash/groupBy'
import gt from 'lodash/gt'
import gte from 'lodash/gte'
import has from 'lodash/has'
import includes from 'lodash/includes'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import join from 'lodash/join'
import last from 'lodash/last'
import lt from 'lodash/lt'
import lte from 'lodash/lte'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import times from 'lodash/times'
import toLower from 'lodash/toLower'
import toPairs from 'lodash/toPairs'
import toString from 'lodash/toString'
import trim from 'lodash/trim'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

// just add here the lodash functions you want to support in chain
const chainableFunctions = {
  map,
  filter,
  toPairs,
  orderBy,
  groupBy,
  sortBy,
  compact,
  join,
}

const chain = (input: any) => {
  let value = input
  const wrapper = {
    ...mapValues(chainableFunctions, (f: Function) => (...args: any) => {
      // lodash always puts input as the first argument
      value = f(value, ...args)
      return wrapper
    }),
    value: () => value,
  }
  return wrapper
}

export {
  isArray,
  concat,
  includes,
  omit,
  uniqBy,
  isEmpty,
  first,
  isEqual,
  gt,
  orderBy,
  lte,
  isNull,
  isUndefined,
  toString,
  find,
  toLower,
  isNumber,
  uniq,
  gte,
  times,
  last,
  trim,
  map,
  sortBy,
  cloneDeep,
  lt,
  merge,
  forOwn,
  has,
  isNil,
  castArray,
  chain,
  isString,
}
