// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dy8XJ{display:flex}.Dy8XJ .BKrNw{width:200px}.Dy8XJ .BKrNw::part(input){border-right:none;border-top-right-radius:0px;border-bottom-right-radius:0px}.Dy8XJ .jrFgA{flex:1 1}.Dy8XJ .jrFgA::part(single-select-input){border-top-left-radius:0px;border-bottom-left-radius:0px}.Dy8XJ .lSMcE::part(single-select-input){border-left-color:var(--wpp-danger-color-400)}.aAA8b{margin-top:4px}`, "",{"version":3,"sources":["webpack://./src/app/components/categorySection/formField/formFieldCurrency/FormFieldCurrency.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,cACE,WAAA,CAEA,2BACE,iBAAA,CACA,2BAAA,CACA,8BAAA,CAIJ,cACE,QAAA,CAEA,yCACE,0BAAA,CACA,6BAAA,CAKF,yCACE,6CAAA,CAKN,OACE,cAAA","sourcesContent":[".fieldContainer {\n  display: flex;\n  .currencyAmountInput {\n    width: 200px;\n\n    &::part(input) {\n      border-right: none;\n      border-top-right-radius: 0px;\n      border-bottom-right-radius: 0px;\n    }\n  }\n\n  .currencyTypeSelect {\n    flex: 1;\n\n    &::part(single-select-input) {\n      border-top-left-radius: 0px;\n      border-bottom-left-radius: 0px;\n    }\n  }\n\n  .currencyTypeError {\n    &::part(single-select-input) {\n      border-left-color: var(--wpp-danger-color-400);\n    }\n  }\n}\n\n.messageContainer {\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldContainer": `Dy8XJ`,
	"currencyAmountInput": `BKrNw`,
	"currencyTypeSelect": `jrFgA`,
	"currencyTypeError": `lSMcE`,
	"messageContainer": `aAA8b`
};
export default ___CSS_LOADER_EXPORT___;
