import { TFunction } from 'i18next'
import moment from 'moment'

import { UserAvatar } from 'app/components/common'
import ReviewerStatus from 'app/features/review/components/reviewers/reviewerStatus'
import { DATE_TIME_FORMAT } from 'constants/field'
import CommonService from 'services/CommonService'
import IReviewerGroup from 'types/review/IReviewerGroup'
import IReviewerInfo from 'types/review/IReviewerInfo'
import ITableHeader from 'types/table/ITableHeader'
import ITableRow from 'types/table/ITableRow'
import { toString } from 'utils/lodash'

/**
 * Get columns for the reviewer table
 * @param {TFunction} t
 * @returns {ITableHeader[]}
 */
export const getColumnData = (t: TFunction): ITableHeader[] => {
  return [
    {
      id: 'name',
      name: t('review.reviewers_list.column_data.name'),
      width: 300,
      enableOrderBy: true,
    },
    {
      id: 'group',
      name: t('review.reviewers_list.column_data.group'),
      width: 300,
      enableOrderBy: false,
    },
    {
      id: 'date',
      name: t('review.reviewers_list.column_data.date'),
      width: 200,
      enableOrderBy: true,
    },
    {
      id: 'details',
      name: t('review.reviewers_list.column_data.details'),
      enableOrderBy: true,
    },
    {
      id: 'status',
      name: t('review.reviewers_list.column_data.status'),
      width: 140,
      enableOrderBy: true,
    },
  ]
}

/**
 * Get rows for the reviewer table
 * @param {IReviewerInfo[]} reviewerData
 * @param {string} requestedAt
 * @returns {ITableRow[]}
 */
export const getRowData = (reviewerData: IReviewerInfo[], requestedAt: string = ''): ITableRow[] => {
  const tableRows: ITableRow[] = reviewerData.map(
    ({
      reviewerFirstname,
      reviewerLastname,
      reviewerEmail,
      reviewerAvatarUrl,
      completedAt,
      responseReason,
      responseType,
      reviewerGroups,
    }: IReviewerInfo) => {
      const date = completedAt ?? requestedAt
      const fullName = `${reviewerFirstname} ${reviewerLastname}`

      return {
        name: {
          component: (
            <UserAvatar
              avatarUrl={toString(reviewerAvatarUrl)}
              email={reviewerEmail}
              fullName={fullName}
              avatarSize="s"
            />
          ),
          text: fullName,
        },
        group: {
          text: CommonService.getValidArray<IReviewerGroup>(reviewerGroups)
            .map((reviewerGroup: IReviewerGroup) => reviewerGroup.group_name)
            .join(', '),
        },
        date: {
          text: date ? moment.utc(date).format(DATE_TIME_FORMAT) : '',
        },
        details: {
          text: responseReason,
        },
        status: {
          component: <ReviewerStatus status={responseType} />,
          text: responseType,
        },
      }
    },
  )
  return tableRows
}
