import React from 'react'
import { useTranslation } from 'react-i18next'

import { WppTag } from 'app/components/common'
import useTerms from 'hooks/useTerms'
import { REVIEW_RESPONSE_TYPES } from 'types/review/enum'
import { isEqual, isNull } from 'utils/lodash'

interface IReviewerStatusProps {
  status: REVIEW_RESPONSE_TYPES | null
}

/**
 * Reviewer status component
 * Shows review status i.e Approved, Reject or Pending
 * @param {object} props
 * @param {REVIEW_RESPONSE_TYPES | null} props.status
 */
const ReviewerStatus: React.FC<IReviewerStatusProps> = ({ status }: IReviewerStatusProps): React.ReactElement => {
  const { t } = useTranslation()
  const termsConfig = useTerms()

  if (isNull(status) || isEqual(status, REVIEW_RESPONSE_TYPES.PENDING)) {
    return <WppTag variant="neutral" label={t('review.reviewers_list.response_types.pending')} />
  }

  if (isEqual(status, REVIEW_RESPONSE_TYPES.APPROVAL)) {
    return <WppTag variant="positive" label={termsConfig('review_response_types_approved')} />
  }

  return <WppTag variant="negative" label={termsConfig('review_response_types_rejected')} />
}

export default ReviewerStatus
