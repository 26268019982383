import { REVIEW_TYPES } from 'types/review/enum'
import IReviewType from 'types/review/IReviewType'

export const REVIEW_TYPES_DATA: IReviewType[] = [
  {
    key: REVIEW_TYPES.ANY_REVIEW,
    title: 'review_any_review_title',
    description: 'review_any_review_description',
  },
  {
    key: REVIEW_TYPES.MAJORITY_OF_REVIEWS,
    title: 'review_majority_title',
    description: 'review_majority_description',
  },
  {
    key: REVIEW_TYPES.ALL_REVIEWS,
    title: 'review_all_title',
    description: 'review_all_description',
  },
]

export const MAX_USERS_FOR_TENANT_GROUPS = 15
