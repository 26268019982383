import { useOs } from '@wpp-open/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import '@platform-ui-kit/components-library/dist/platform-ui-kit/global.css'

import styles from 'app/App.module.scss'
import Spinner from 'app/components/common/spinner/Spinner'
import Toast from 'app/components/common/toast'
import { API_TYPES, apiConfig } from 'constants/appConfig'
import { useMount } from 'hooks'
import ProjectBrief from 'pages/projectBrief'
import { AppDispatch, RootState } from 'store'
import { RESET_GLOBAL_STORE } from 'store/actions'
import IAppContextState from 'store/interfaces/IAppContextState'
import { setAppContext } from 'store/reducers/appContextSlice'
import { isEmpty } from 'utils/lodash'

export function App() {
  /**
   * This hook is provided by the utility package for more convenient work with OS context
   * @return osContext
   * - is a subscribe function that returns you the main context object with MicroAppContext data
   * @return osApi
   * - methods to fire actions on the parent OS level
   *
   * Read more about it in the README file
   */
  const { osContext } = useOs()
  const appContextState: IAppContextState = useSelector<RootState, IAppContextState>(
    (state: RootState) => state.appContext,
  )
  const loading: boolean = useSelector<RootState, boolean>((state: RootState) => state.loaderState)
  const dispatch = useDispatch<AppDispatch>()
  console.log('[OS Context] ', osContext, process.env.NODE_ENV)
  console.log('[NAMESPACE]', apiConfig[API_TYPES.APP_NAMESPACE])
  console.log('[PROJECT_API]', apiConfig[API_TYPES.PROJECT_API])
  console.log('[MASTER_API]', apiConfig[API_TYPES.MASTER_API])
  console.log('[FACADE_API]', apiConfig[API_TYPES.FACADE_API])

  useEffect(() => {
    dispatch(setAppContext(osContext))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osContext, osContext.project])

  useMount(() => {
    return () => {
      dispatch({ type: RESET_GLOBAL_STORE })
    }
  })

  if (appContextState.loading || isEmpty(appContextState.projectId)) {
    return <Spinner />
  }
  return (
    <BrowserRouter basename={osContext.baseUrl ?? ''}>
      <div id="app" className={styles.container}>
        <Routes>
          <Route index element={<ProjectBrief />} />
          <Route path="/change-log" element={<ProjectBrief />} />
        </Routes>
        <Toast />
        {loading && <Spinner />}
      </div>
    </BrowserRouter>
  )
}
