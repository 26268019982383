import { WppInputCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import React from 'react'

import styles from 'app/components/categorySection/CategorySection.module.scss'
import { WppInput } from 'app/components/common'
import HelpSection from 'app/components/common/helpSection'
import { InputChangeEventDetail } from 'types/common/utils'
import IField from 'types/field/IField'
import IFieldChangeEvent from 'types/field/IFieldChangeEvent'
import { isEqual } from 'utils/lodash'

interface IFormFieldNumberProps {
  field: IField
  handleChange: (event: IFieldChangeEvent) => void
  id?: string
}

/**
 * Create number input field
 * @param {object} props
 * @param {IField} props.field
 * @param {IField} props.id
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 */
const FormFieldTextInput: React.FC<IFormFieldNumberProps> = ({
  field,
  handleChange,
  id,
}: IFormFieldNumberProps): React.ReactElement => {
  const { disabled, messageType, fieldConfig, value } = field
  const { placeholder } = fieldConfig

  return (
    <>
      <WppInput
        placeholder={placeholder}
        value={value}
        onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => {
          handleChange({
            field,
            value: event.detail.value,
          })
        }}
        maskOptions={{ customPatternOptions: { mask: /^\d*$/ } }}
        type="text"
        id={id ?? `numberInput-${field.id}`}
        disabled={disabled}
        messageType={messageType}
        message=""
        className={isEqual(messageType, 'error') ? '' : styles.customStyleDefaultMessage}
      />
      <HelpSection field={field} value={field.value} />
    </>
  )
}

export default FormFieldTextInput
