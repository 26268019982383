import { AxiosResponse } from 'axios'

import {
  getDownloadFileAPIPath,
  getFileMetaData,
  getUploadFileAPIPath,
  getUploadSignedAPIPath,
} from 'constants/apiPaths'
import AxiosService from 'lib/AxiosService'
import FormFieldValidationService from 'services/formField/FormFieldValidationService'
import IApp from 'types/app/IApp'
import IFieldAttachment from 'types/field/attachment/IFieldAttachment'
import { FIELD_TYPES } from 'types/field/enum'
import IField from 'types/field/IField'
import IFile from 'types/file/IFile'
import IFileSignedURLResponse from 'types/file/IFileSignedURLResponse'
import IUploadedFile from 'types/file/IUploadFile'
import { chain, concat, find, first, isArray, isEmpty, isEqual, isNull } from 'utils/lodash'

export default class FileService {
  private readonly axiosService: AxiosService
  constructor(accessToken: string = '') {
    this.axiosService = new AxiosService(accessToken)
  }

  /**
   * Create a file preview path for the uploaded file in Project
   * @param {string} homeUrl
   * @param {string} projectId
   * @param {string} fileId
   * @returns {string}
   */
  public static buildFilePreviewPath(homeUrl: string, projectId: string, fileId: string): string {
    return `${homeUrl}orchestration/project/${projectId}/files?view=fileDetailsPreview&id=${fileId}`
  }

  /**
   * Create Upload Signed URL
   * @param {File} file
   * @param {string} tenantId
   * @param {string} projectId
   * @returns {Promise<IFileSignedURLResponse[]>}
   */
  async getUploadSignedURL(file: File, tenantId: string, projectId: string): Promise<IFileSignedURLResponse[]> {
    const Response: AxiosResponse<IFileSignedURLResponse[]> = await this.axiosService.post(
      getUploadSignedAPIPath(),
      {
        projectId,
        names: [file.name],
        contentType: file.type,
      },
      tenantId,
    )
    return Response.data
  }

  /**
   * Upload file to a project
   * @param {File} file
   * @param {string} tenantId
   * @param {string} homeUrl
   * @param {string} projectId
   * @returns {Promise<IFile>}
   */
  async uploadFile(file: File, tenantId: string, homeUrl: string, projectId: string): Promise<IFile> {
    const UpdatedFile: File = new File([file], file.name, {
      type: file.type,
    })

    const signedURLs: IFileSignedURLResponse[] = await this.getUploadSignedURL(UpdatedFile, tenantId, projectId)

    const uploadFileSignedURL: IFileSignedURLResponse = first(signedURLs) as IFileSignedURLResponse

    await this.axiosService.put(uploadFileSignedURL.signedUrl, UpdatedFile, tenantId, {
      headers: {
        'Content-Type': UpdatedFile.type,
      },
    })

    const uploadedFile: AxiosResponse<IUploadedFile[]> = await this.axiosService.post(
      getUploadFileAPIPath(projectId),
      [
        {
          key: uploadFileSignedURL.key,
          name: uploadFileSignedURL.name,
          size: UpdatedFile.size,
        },
      ],
      tenantId,
    )

    const { key, id } = first(uploadedFile.data) as IUploadedFile

    return {
      file: {
        name: file.name,
        size: file.size,
        type: file.type,
        url: FileService.buildFilePreviewPath(homeUrl, projectId, id),
        key,
      },
      id,
    }
  }

  /**
   * Get Input Files
   * @param {CustomEvent} event
   * @param {IFile[]} fileValue
   * @returns {IFile[]}
   */
  getInputFiles(event: CustomEvent, fileValue: IFile[]): IFile[] {
    // Valid files
    const ValidFiles = event.detail.value.map((file: File) => ({
      file,
      id: null,
    }))

    // Error files due to large size, incorrect file type
    const ErrorFiles = event.detail.errorFiles.map((file: File) => ({
      file,
      id: null,
    }))

    const Files = concat(ValidFiles, ErrorFiles)

    if (isEmpty(Files)) {
      return []
    }

    let fileValues: IFile[] = []

    // Update Removed File
    fileValues = fileValue.filter(({ file }: IFile) => {
      const IsFilePresent = Files.findIndex((inputFile: IFile) => isEqual(inputFile.file.name, file.name))
      return !isEqual(IsFilePresent, -1)
    })

    const UpdatedFiles = Files.filter(({ file }: IFile) => {
      const IsValidInput = fileValues.find((fileInput: IFile) => isEqual(file.name, fileInput.file.name))
      return !IsValidInput
    })
    return concat(fileValues, UpdatedFiles)
  }

  /**
   * Get file info
   * @param {IFile} file
   * @param {string} tenantId
   * @returns {Promise<string>}
   */
  public async getFileInfo(file: IFile, tenantId: string): Promise<string | null> {
    if (file.file.key) {
      return file.file.key
    }

    if (isNull(file.id)) return null
    const response: { data: { key: string } } = await this.axiosService.get(getFileMetaData(file.id), tenantId)
    return response.data.key
  }

  /**
   * Get signed urls for attachments
   * @param {IFieldAttachment[]} attachments
   * @param {string} tenantId
   * @returns {Promise<IFieldAttachment[]>}
   */
  public async downloadAttachments(attachments: IFieldAttachment[], tenantId: string): Promise<IFieldAttachment[]> {
    if (isEmpty(attachments)) return []

    const response: { data: { key: string; signedUrl: string }[] } = await this.axiosService.post(
      getDownloadFileAPIPath(),
      {
        download: true,
        keys: attachments.map((attachment: IFieldAttachment) => attachment.key),
      },
      tenantId,
    )

    return response.data.map((attachment: { key: string; signedUrl: string }) => {
      const file: any = find(attachments, { key: attachment.key })
      return {
        ...file,
        title: file.title ?? file.id,
        signedUrl: attachment.signedUrl,
      }
    })
  }

  /**
   * Get file unique key
   * @param {IField} field
   * @param {IFile} file
   * @param {string} tenantId
   * @returns {Promise<IFieldAttachment>}
   */
  public async getFileKey(field: IField, file: IFile, tenantId: string): Promise<IFieldAttachment> {
    const key = await this.getFileInfo(file, tenantId)
    return { key, title: field.fieldConfig.text, id: field.id, fileName: file.file.name }
  }

  /**
   * Get field attachments
   * @param {IField} field
   * @param {string} tenantId
   * @returns {Promise<IFieldAttachment[]>}
   */
  public async getFieldAttachments(field: IField, tenantId: string): Promise<IFieldAttachment[]> {
    let files: IFieldAttachment[] = []
    if (isEqual(FIELD_TYPES.FILE_UPLOAD, field.type) && !FormFieldValidationService.isEmptyField(field)) {
      if (isArray(field.value)) {
        for (let file of field.value) {
          const key = await this.getFileKey(field, file, tenantId)
          files.push(key)
        }
      } else {
        const { file } = field.value
        const key = await this.getFileKey(field, file, tenantId)
        files.push(key)
      }
    }

    for (let childField of field.children) {
      files = concat(files, await this.getFieldAttachments(childField, tenantId))
    }
    return files.filter((file: IFieldAttachment) => !isNull(file.key))
  }

  /**
   * Get field file attachments
   * @param {IApp} app
   * @param {string} tenantId
   * @returns {Promise<{ id: string; files: IFieldAttachment[] }[]>}
   */
  public async getAllAttachments(app: IApp, tenantId: string): Promise<{ id: string; files: IFieldAttachment[] }[]> {
    let files: IFieldAttachment[] = []
    for (let category of app.categories) {
      for (let form of category.forms) {
        for (let field of form.fields) {
          const data = await this.getFieldAttachments(field, tenantId)
          files = concat(files, data)
        }
      }
    }
    const response = await this.downloadAttachments(files, tenantId)
    return chain(response)
      .groupBy('title')
      .map((value: any, key: string) => ({ id: key, files: value }))
      .value()
  }
}
