import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import IReviewState from 'store/interfaces/IReviewState'
import IProjectMember from 'types/project/IProjectMember'
import IReviewerInfo from 'types/review/IReviewerInfo'
import ITenantUserGroup from 'types/tenant/ITenantUserGroup'

export const defaultReviewState: IReviewState = {
  isApproveModalOpen: false,
  isRejectModalOpen: false,
  isSendToReviewModalOpen: false,
  isViewStatusModalOpen: false,
  isCancelReviewModalOpen: false,
  reviewers: [],
  tenantUserGroups: [],
  selectedReviewers: [],
  selectedTenantUserGroups: [],
  reviewerInfo: null,
}

const reviewSlice = createSlice({
  name: 'review',
  initialState: defaultReviewState,
  reducers: {
    setSendToReviewModal: (state: IReviewState, action: PayloadAction<boolean>): IReviewState => {
      return {
        ...state,
        isSendToReviewModalOpen: action.payload,
      }
    },
    setViewStatusModal: (state: IReviewState, action: PayloadAction<boolean>): IReviewState => {
      return {
        ...state,
        isViewStatusModalOpen: action.payload,
      }
    },
    setApproveModal: (state: IReviewState, action: PayloadAction<boolean>): IReviewState => {
      return {
        ...state,
        isApproveModalOpen: action.payload,
      }
    },
    setRejectModal: (state: IReviewState, action: PayloadAction<boolean>): IReviewState => {
      return {
        ...state,
        isRejectModalOpen: action.payload,
      }
    },
    setCancelReviewModal: (state: IReviewState, action: PayloadAction<boolean>): IReviewState => {
      return {
        ...state,
        isCancelReviewModalOpen: action.payload,
      }
    },
    setReviewers: (state: IReviewState, action: PayloadAction<IProjectMember[]>): IReviewState => {
      return {
        ...state,
        reviewers: action.payload,
      }
    },
    setTenantUserGroups: (state: IReviewState, action: PayloadAction<ITenantUserGroup[]>): IReviewState => {
      return {
        ...state,
        tenantUserGroups: action.payload,
      }
    },
    setReviewerInfo: (state: IReviewState, action: PayloadAction<IReviewerInfo | null>): IReviewState => {
      return {
        ...state,
        reviewerInfo: action.payload,
      }
    },
    setSelectedReviewers: (state: IReviewState, action: PayloadAction<IReviewerInfo[]>): IReviewState => {
      return {
        ...state,
        selectedReviewers: action.payload,
      }
    },
    setSelectedReviewersAndGroups: (
      state: IReviewState,
      {
        payload: { selectedTenantUserGroups, selectedReviewers },
      }: PayloadAction<{
        selectedTenantUserGroups: ITenantUserGroup[]
        selectedReviewers: IReviewerInfo[]
      }>,
    ): IReviewState => {
      return {
        ...state,
        selectedReviewers,
        selectedTenantUserGroups,
      }
    },
  },
})

export const {
  setApproveModal,
  setRejectModal,
  setCancelReviewModal,
  setSendToReviewModal,
  setViewStatusModal,
  setReviewers,
  setSelectedReviewers,
  setReviewerInfo,
  setTenantUserGroups,
  setSelectedReviewersAndGroups,
} = reviewSlice.actions

export default reviewSlice.reducer
