import { WppDatepickerCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import classNames from 'classnames'
import React from 'react'

import styles from 'app/components/categorySection/CategorySection.module.scss'
import { WppDatepicker } from 'app/components/common'
import { DatePickerEventDetail } from 'types/common/utils'
import { FIELD_TYPES } from 'types/field/enum'
import IField from 'types/field/IField'
import IFieldChangeEvent from 'types/field/IFieldChangeEvent'
import { isEqual } from 'utils/lodash'

interface IFormFieldDatePickerProps {
  field: IField
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create datepicker field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldDatePicker: React.FC<IFormFieldDatePickerProps> = ({
  field,
  handleChange,
}: IFormFieldDatePickerProps): React.ReactElement => {
  const { disabled, fieldConfig, value, messageType, errorMessage, isRequired } = field
  const { placeholder } = fieldConfig
  const datePickerReadOnlyClass = disabled ? styles.datePickerReadOnly : ''

  return (
    <WppDatepicker
      onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
        handleChange({
          field,
          value: !event.detail.formattedDate ? null : event.detail.formattedDate,
        })
      }}
      locale={{
        dateFormat: 'MM/dd/yyyy',
      }}
      onWppDateClear={() => {
        handleChange({
          field,
          value: isEqual(field.type, FIELD_TYPES.DATE_RANGE_PICKER) ? [] : null,
        })
      }}
      range={isEqual(field.type, FIELD_TYPES.DATE_RANGE_PICKER)}
      required={isRequired}
      placeholder={placeholder}
      id={`datepicker-${field.id}`}
      messageType={messageType}
      message={isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
      className={
        isEqual(messageType, 'error') ? '' : classNames(styles.customStyleDefaultMessage, datePickerReadOnlyClass)
      }
      value={value}
    />
  )
}

export default FormFieldDatePicker
