import classNames from 'classnames'
import React from 'react'

import { WppInlineMessage } from 'app/components/common'
import CharacterLimit from 'app/components/common/characterLimit'
import styles from 'app/components/common/helpSection/HelpSection.module.scss'
import IField from 'types/field/IField'
import { isEmpty } from 'utils/lodash'

interface HelpSectionProps {
  field: IField
  value: string
}

/**
 * Input action character limit and help text component for TextInput and Number fields
 * Shows number of character or error in case over max length and help text
 * @param {object} props
 * @param {IField} props.field
 * @param {string} props.value
 */
const HelpSection: React.FC<HelpSectionProps> = ({ field, value }: HelpSectionProps) => {
  const { fieldConfig, errorMessage } = field
  return (
    <div className={styles.helpSection}>
      <WppInlineMessage
        size="s"
        className={classNames({
          [styles.inlineHelpMessage]: isEmpty(errorMessage),
          [styles.inlineErrorMessage]: !isEmpty(errorMessage),
        })}
        message={isEmpty(errorMessage) ? fieldConfig.help : errorMessage}
        type={isEmpty(errorMessage) ? undefined : 'error'}
      />
      {!field.disabled && fieldConfig.charactersLimit && (
        <CharacterLimit textCharCount={value.length} charactersLimit={fieldConfig.charactersLimit} />
      )}
    </div>
  )
}

export default HelpSection
