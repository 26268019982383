import classnames from 'classnames'
import { Trans } from 'react-i18next'

import { WppTypography } from 'app/components/common'
import styles from 'app/components/common/characterLimit/CharacterLimit.module.scss'
import { getCharactersWarning } from 'utils/common'
import { gt, gte, lt } from 'utils/lodash'

interface CharacterLimitProps {
  textCharCount: number
  charactersLimit: number
}

/**
 * Input action character limit component for MentionEditor and TextInput and Number fields
 * Shows number of character or error in case over max length
 * @param {object} props
 * @param {number} props.textCharCount
 * @param {number} props.charactersLimit
 */
const CharacterLimit = ({ textCharCount, charactersLimit }: CharacterLimitProps) => {
  let charWarning = getCharactersWarning(charactersLimit)
  let textColorStyle = gte(textCharCount, charactersLimit - charWarning)
    ? styles.characterLimitLabelWarning
    : styles.characterLimitLabel

  if (lt(charactersLimit, 20)) {
    textColorStyle = styles.characterLimitLabel
  }

  if (gt(textCharCount, charactersLimit)) {
    textColorStyle = styles.characterLimitLabelError
  }

  return (
    <div className={styles.container}>
      <div className={classnames(styles.characterLimit, textColorStyle)}>
        <WppTypography className={textColorStyle} tag="p" type="xs-body">
          <Trans>comment.text.characters</Trans>:
        </WppTypography>
        <WppTypography className={textColorStyle} tag="p" type="xs-strong">
          {textCharCount}/{charactersLimit}
        </WppTypography>
      </div>
    </div>
  )
}

export default CharacterLimit
