// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gaJbx{display:flex;align-items:center;justify-content:flex-end}.gaJbx .T8aSl{display:flex;align-items:center}.gaJbx .lZkac{display:flex;align-items:center;justify-content:flex-end;gap:2px;margin-top:4px;color:var(--wpp-grey-color-800)}.gaJbx .kbQaP{color:var(--wpp-grey-color-800)}.gaJbx .TqcHn{color:var(--wpp-danger-color-500)}.gaJbx .UNKjV{color:var(--wpp-warning-color-500)}`, "",{"version":3,"sources":["webpack://./src/app/components/common/characterLimit/CharacterLimit.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,wBAAA,CAEA,cACE,YAAA,CACA,kBAAA,CAGF,cACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,OAAA,CACA,cAAA,CACA,+BAAA,CAGF,cACE,+BAAA,CAGF,cACE,iCAAA,CAGF,cACE,kCAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n\n  .input {\n    display: flex;\n    align-items: center;\n  }\n\n  .characterLimit {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    gap: 2px;\n    margin-top: 4px;\n    color: var(--wpp-grey-color-800);\n  }\n\n  .characterLimitLabel {\n    color: var(--wpp-grey-color-800);\n  }\n\n  .characterLimitLabelError {\n    color: var(--wpp-danger-color-500);\n  }\n\n  .characterLimitLabelWarning {\n    color: var(--wpp-warning-color-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `gaJbx`,
	"input": `T8aSl`,
	"characterLimit": `lZkac`,
	"characterLimitLabel": `kbQaP`,
	"characterLimitLabelError": `TqcHn`,
	"characterLimitLabelWarning": `UNKjV`
};
export default ___CSS_LOADER_EXPORT___;
