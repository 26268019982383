import { HeadingLevel, Paragraph, Document, AlignmentType, ExternalHyperlink, TextRun } from 'docx'
import { TFunction } from 'i18next'

import PDFHelper from 'app/features/export/services/PdfService'
import { DOCX_FONT_FAMILY_NAME } from 'constants/file'
import FileService from 'services/file/FileService'
import FormFieldValidationService from 'services/formField/FormFieldValidationService'
import IApp from 'types/app/IApp'
import ICategory from 'types/category/ICategory'
import IOption from 'types/common/IOption'
import { FIELD_TYPES } from 'types/field/enum'
import IField from 'types/field/IField'
import IRepeatableField from 'types/field/repeatable/IRepeatableField'
import IFile from 'types/file/IFile'
import IForm from 'types/form/IForm'
import { isArray, concat, toString, isUndefined, orderBy, gt, isEmpty, isEqual } from 'utils/lodash'

export default class DocService {
  /**
   * Get field value
   * @param {IField} field
   * @param {string} homeUrl
   * @param {string} projectId
   * @returns {Paragraph[]}
   */
  public static getFieldValue(field: IField, homeUrl: string, projectId: string): Paragraph[] {
    let fieldValue: Paragraph[] = []
    const value = PDFHelper.getFieldValue(field)
    if (FormFieldValidationService.isEmptyField(field)) {
      return [this.createFieldValue('-')]
    }

    switch (field.type) {
      case FIELD_TYPES.FILE_UPLOAD: {
        if (isArray(value)) {
          const data: Paragraph[] = value.map(({ file, id }: IFile) =>
            this.createHyperLink(file.name, FileService.buildFilePreviewPath(homeUrl, projectId, id)),
          )
          fieldValue = concat(fieldValue, data)
        }
        break
      }
      case FIELD_TYPES.TEXT_INPUT:
      case FIELD_TYPES.TEXT_AREA:
      case FIELD_TYPES.COUNTER:
      case FIELD_TYPES.NUMBER:
      case FIELD_TYPES.DATE_RANGE_PICKER:
      case FIELD_TYPES.DATE_PICKER: {
        fieldValue.push(this.createFieldValue(toString(value)))
        break
      }
      case FIELD_TYPES.CURRENCY: {
        fieldValue.push(
          this.createFieldValue(value.currencyType ? `${value.currencyAmount} ${value.currencyType}` : ''),
        )
        break
      }
      case FIELD_TYPES.RADIO: {
        const option: undefined | IOption = PDFHelper.getRadioValue(field)
        fieldValue.push(this.createFieldValue(!isUndefined(option) ? option.label : '-'))
        break
      }
      case FIELD_TYPES.CHECKBOX: {
        if (isArray(value)) {
          const data: Paragraph[] = orderBy(value, ['id'], 'asc').map(
            (option: IOption): Paragraph => this.createBulletValue(option.label),
          )
          fieldValue = concat(fieldValue, data)
        }
        break
      }
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.MARKET:
      case FIELD_TYPES.USER:
      case FIELD_TYPES.USER_MENTION:
      case FIELD_TYPES.AUTOCOMPLETE: {
        if (isArray(value)) {
          const data: Paragraph[] = value.map((val: string): Paragraph => this.createBulletValue(val))
          fieldValue = concat(fieldValue, data)
        }
        break
      }
    }
    return fieldValue
  }

  /**
   * Get form fields
   * @param {IField[]} fields
   * @param {string} homeUrl
   * @param {string} projectId
   * @returns {Paragraph[]}
   */
  public static getFields(fields: IField[], homeUrl: string, projectId: string): Paragraph[] {
    let data: Paragraph[] = []
    fields.forEach((field: IField) => {
      if (field.isHidden) return
      if (gt(field.maxRepeatableAmount, 0)) {
        field.repeatableFields.forEach((rField: IRepeatableField, index: number) => {
          const updatedField: IField = { ...field, value: rField.value }
          const fieldLabel = !field.fieldConfig.text ? '' : `${field.fieldConfig.text} ${index + 1}`
          if (fieldLabel) data.push(this.createFieldHeading(fieldLabel))
          data = concat(data, this.getFieldValue(updatedField, homeUrl, projectId))
        })
      } else {
        if (field.fieldConfig.text) data.push(this.createFieldHeading(field.fieldConfig.text))
        data = concat(data, this.getFieldValue(field, homeUrl, projectId))
      }
      data = concat(data, this.getFields(field.children, homeUrl, projectId))
    })
    return data
  }

  /**
   * Get forms
   * @param {IForm[]} forms
   * @param {string} homeUrl
   * @param {string} projectId
   * @returns {Paragraph[]}
   */
  public static getForms(forms: IForm[], homeUrl: string, projectId: string): Paragraph[] {
    let data: Paragraph[] = []

    forms.forEach((form: IForm) => {
      if (!isEmpty(form.name)) {
        data.push(this.createFormHeading(form.name))
      }
      data = concat(data, this.getFields(form.fields, homeUrl, projectId))
    })
    return data
  }

  /**
   * Get categories
   * @param {ICategory[]} categories
   * @param {string} projectQuestionnaireId
   * @param {string} homeUrl
   * @param {string} projectId
   * @param {TFunction} t
   * @returns {Paragraph[]}
   */
  public static getCategories(
    categories: ICategory[],
    projectQuestionnaireId: string,
    homeUrl: string,
    projectId: string,
    t: TFunction,
  ): Paragraph[] {
    let data: Paragraph[] = []
    categories.forEach((category: ICategory, index: number) => {
      data.push(this.createCategoryHeading(category.name))
      if (isEqual(index, 0)) {
        data.push(this.createFieldHeading(t('form.brief_id_number')))
        data.push(this.createFieldValue(projectQuestionnaireId))
      }
      data = concat(data, this.getForms(category.forms, homeUrl, projectId))
    })
    return data
  }

  /**
   * Create document
   * @param {IApp} app
   * @param {string} projectQuestionnaireId
   * @param {string} homeUrl
   * @param {string} projectId
   * @param {TFunction} t
   * @returns {Document}
   */
  public static createDoc(
    app: IApp,
    projectQuestionnaireId: string,
    homeUrl: string,
    projectId: string,
    t: TFunction,
  ): Document {
    const document = new Document({
      title: app.appName,
      styles: {
        paragraphStyles: [
          {
            id: 'briefName',
            run: {
              bold: true,
              font: DOCX_FONT_FAMILY_NAME,
              size: 40,
            },
          },
          {
            id: 'category',
            run: {
              bold: true,
              font: DOCX_FONT_FAMILY_NAME,
              size: 32,
            },
            paragraph: {
              spacing: {
                before: 200,
              },
            },
          },
          {
            id: 'form',
            run: {
              bold: true,
              font: DOCX_FONT_FAMILY_NAME,
              size: 28,
            },
            paragraph: {
              spacing: {
                before: 200,
              },
            },
          },
          {
            id: 'field',
            run: {
              bold: true,
              font: DOCX_FONT_FAMILY_NAME,
              size: 24,
            },
            paragraph: {
              spacing: {
                before: 200,
              },
            },
          },
          {
            id: 'text',
            run: {
              font: DOCX_FONT_FAMILY_NAME,
              size: 24,
            },
          },
        ],
      },
      sections: [
        {
          children: [
            new Paragraph({
              text: app.appName,
              heading: HeadingLevel.HEADING_1,
              style: 'briefName',
              alignment: AlignmentType.CENTER,
            }),
            ...this.getCategories(app.categories, projectQuestionnaireId, homeUrl, projectId, t),
          ],
        },
      ],
    })
    return document
  }

  /**
   * Create category heading text
   * @param {string} text
   * @returns {Paragraph}
   */
  public static createCategoryHeading(text: string = ''): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      thematicBreak: true,
      style: 'category',
    })
  }

  /**
   * Create form text
   * @param {string} text
   * @returns {Paragraph}
   */
  public static createFormHeading(text: string = ''): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
      style: 'form',
    })
  }

  /**
   * Create field heading text
   * @param {string} text
   * @returns {Paragraph}
   */
  public static createFieldHeading(text: string = ''): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_3,
      style: 'field',
    })
  }

  /**
   * Create field value
   * @param {string} text
   * @returns {Paragraph}
   */
  public static createFieldValue(text: string = ''): Paragraph {
    return new Paragraph({
      text,
      style: 'text',
    })
  }

  /**
   * Create bullet value
   * @param {string} text
   * @returns {Paragraph}
   */
  public static createBulletValue(text: string = ''): Paragraph {
    return new Paragraph({
      text: text,
      style: 'text',
      bullet: {
        level: 0,
      },
    })
  }

  /**
   * Create hyperlink
   * @param {string} text
   * @param {string} link
   * @returns {Paragraph}
   */
  public static createHyperLink(text: string = '', link: string = ''): Paragraph {
    return new Paragraph({
      bullet: {
        level: 0,
      },
      style: 'text',
      children: [
        new ExternalHyperlink({
          children: [
            new TextRun({
              text,
              style: 'Hyperlink',
            }),
          ],
          link,
        }),
      ],
    })
  }
}
