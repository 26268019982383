import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'app/components/categorySection/formField/formFieldCurrency/FormFieldCurrency.module.scss'
import { WppGrid, WppInput, WppListItem, WppSelect } from 'app/components/common'
import HelpSection from 'app/components/common/helpSection'
import FormFieldValidationService from 'services/formField/FormFieldValidationService'
import IOption from 'types/common/IOption'
import { InputChangeEventDetail, WppInputCustomEvent } from 'types/common/utils'
import IField from 'types/field/IField'
import IFieldChangeEvent from 'types/field/IFieldChangeEvent'

interface IFormFieldCurrencyProps {
  field: IField
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create currency field
 * @param {object} props
 * @param {IField} props.field
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 */
const FormFieldCurrency: React.FC<IFormFieldCurrencyProps> = ({
  field,
  handleChange,
}: IFormFieldCurrencyProps): React.ReactElement => {
  const { t } = useTranslation()
  const { currencyType, currencyAmount } = field.value
  const { disabled, fieldConfig, value } = field
  const { placeholder, text, options } = fieldConfig

  const { currencyAmountMessageType, currencyNameMessageType, isError } =
    FormFieldValidationService.validateCurrencyAmountType(field)

  return (
    <WppGrid fullWidth container>
      <WppGrid item all={24}>
        <div className={styles.fieldContainer}>
          <WppInput
            placeholder={placeholder ?? t('form_fields.currency_amount_placeholder')}
            value={currencyAmount}
            onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) =>
              handleChange({
                field,
                value: {
                  currencyType,
                  currencyAmount: event.detail.value,
                },
              })
            }
            type="text"
            id={`currency-textinput-${field.id}`}
            disabled={disabled}
            messageType={currencyAmountMessageType}
            name={text}
            className={styles.currencyAmountInput}
          />
          <WppSelect
            onWppChange={(event: CustomEvent) =>
              handleChange({
                field,
                value: {
                  currencyType: event.detail.value,
                  currencyAmount,
                },
              })
            }
            id={`currency-select-${field.id}`}
            placeholder={t('form_fields.currency_name_placeholder')}
            value={value.currencyType}
            withSearch
            disabled={disabled}
            type="single"
            messageType={currencyNameMessageType}
            className={classNames(styles.currencyTypeSelect, { [styles.currencyTypeError]: isError })}
            name={text}
          >
            {options.map((option: IOption) => (
              <WppListItem value={option.id} key={option.id}>
                <p slot="label">{option.label}</p>
              </WppListItem>
            ))}
          </WppSelect>
        </div>
        <HelpSection field={field} value={currencyAmount ?? ''} />
      </WppGrid>
    </WppGrid>
  )
}

export default FormFieldCurrency
