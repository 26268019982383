import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import React, { Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'

import styles from 'app/components/common/table/tableBody/TableBody.module.scss'
import ITableHeader from 'types/table/ITableHeader'
import { times } from 'utils/lodash'

interface ITableHeaderProps {
  colDefs: ITableHeader[]
  itemsPerPage: number
}

/**
 * Table header component
 * @param {object} props
 * @param {ITableHeader[]} props.colDefs
 * @param {boolean} props.loading
 */
const TableLoader: React.FC<ITableHeaderProps> = ({ colDefs, itemsPerPage }: ITableHeaderProps): React.ReactElement => {
  return (
    <>
      {times(itemsPerPage).map(() => {
        return (
          <tr key={uuidv4()} className={styles.tableRow}>
            {colDefs.map((column: ITableHeader) => {
              if (column.hidden) return <Fragment key={column.id} />
              return (
                <th
                  style={{ width: column.width ?? 'auto', minWidth: column.minWidth ?? 'auto' }}
                  key={column.id}
                  className={styles.tableCell}
                >
                  <WppSkeleton height={30} />
                </th>
              )
            })}
          </tr>
        )
      })}
    </>
  )
}

export default TableLoader
