import { useOs } from '@wpp-open/react'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { WppButton, WppGrid, WppTypography } from 'app/components/common'
import { ApplicationError, NotAuthorizedError } from 'app/components/common/errorComponents'
import { ConfirmationModal } from 'app/components/common/modals'
import Spinner from 'app/components/common/spinner/Spinner'
import QuestionnaireSelection from 'app/components/projectBrief'
import { useMount } from 'hooks'
import useTerms from 'hooks/useTerms'
import styles from 'pages/projectBrief/ProjectBrief.module.scss'
import Questionnaire from 'pages/questionnaire/Questionnaire'
import ProjectBriefService from 'services/projectBrief/ProjectBriefService'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import { setOpenModal, setParentQuestionnaireItem } from 'store/reducers/projectBriefSlice'
import IOption from 'types/common/IOption'
import { QUESTIONNAIRE_STATUS } from 'types/review/enum'
import { isEmpty, isEqual, isNull } from 'utils/lodash'

/**
 * Project Brief Component
 * Shows briefs to the user for selection or redirect user to the brief if already selected
 */
const ProjectBrief: React.FC = (): React.ReactElement => {
  const { osApi } = useOs()
  const termsConfig = useTerms()
  const { t } = useTranslation()

  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const dispatch = useDispatch<AppDispatch>()

  const { initLoading, loading, openModal, showQuestionnaireSelection, disableFormSelection } = projectBriefState
  const { unauthorizedApp, unauthorizedQuestionnaire, serverError } = projectBriefState.error
  const { parentQuestionnaireItemId, parentQuestionnaires, questionnaireId, questionnaires, projectQuestionnaireId } =
    projectBriefState.questionnaire

  const accessToken: string = osApi.getAccessToken()

  const handleQuestionnaireSelection = (option: IOption) => {
    ProjectBriefService.handleQuestionnaireSelection(
      accessToken,
      appContext,
      option,
      projectBriefState,
      termsConfig,
      dispatch,
    )
  }

  const handleParentQuestionnaireSelection = (option: IOption) => {
    dispatch(setParentQuestionnaireItem(option.id))
  }

  const onSaveProjectQuestionnaire = async () => {
    ProjectBriefService.onSaveProjectQuestionnaire(accessToken, appContext, projectBriefState, dispatch)
  }

  useMount(() => {
    ProjectBriefService.initLoad(accessToken, projectBriefState, appContext, t, termsConfig, dispatch)
  })

  if (serverError) {
    return <ApplicationError />
  }

  if (unauthorizedApp) {
    return <NotAuthorizedError description={t('project_brief.unauthorized')} />
  }

  if (!isEmpty(unauthorizedQuestionnaire)) {
    return <NotAuthorizedError description={unauthorizedQuestionnaire} />
  }

  let isQuestionnaireSelection = isEqual(
    projectBriefState.questionnaire.approval?.status,
    QUESTIONNAIRE_STATUS.SUCCEEDED,
  )
    ? false
    : showQuestionnaireSelection

  return (
    <>
      <ConfirmationModal
        title={t('project_brief.confirmation_modal.title')}
        body={t('project_brief.confirmation_modal.body')}
        isOpen={openModal}
        handlePrimaryAction={onSaveProjectQuestionnaire}
        handleSecondaryAction={() => dispatch(setOpenModal(false))}
      />
      {(initLoading || loading) && <Spinner />}
      {!initLoading && isQuestionnaireSelection && (
        <WppGrid container className={styles.layout} rowSpacing={2}>
          <WppGrid item all={24} className={styles.layoutHeader}>
            <WppTypography tag="h1" type="2xl-heading">
              <Trans>project_brief.title</Trans>
            </WppTypography>
          </WppGrid>
          <WppGrid className={styles.layoutForm} item all={24}>
            <WppGrid container className={styles.form}>
              <WppGrid item all={8}>
                <WppTypography className={styles.name} tag="h2" type="xl-heading">
                  <Trans>project_brief.form.title</Trans>
                </WppTypography>
                <p className={styles.description}>
                  <Trans>project_brief.form.desc</Trans>
                </p>
                <QuestionnaireSelection
                  parentQuestionnaires={parentQuestionnaires}
                  questionnaires={questionnaires}
                  handleParentQuestionnaireSelection={handleParentQuestionnaireSelection}
                  handleQuestionnaireSelection={handleQuestionnaireSelection}
                  parentQuestionnaireItemId={parentQuestionnaireItemId}
                  questionnaireId={questionnaireId}
                  disableFormSelection={disableFormSelection}
                />
                <WppButton
                  disabled={!questionnaireId || (!isEmpty(parentQuestionnaires) && !parentQuestionnaireItemId)}
                  id="btn-save"
                  onClick={() => dispatch(setOpenModal(true))}
                >
                  <Trans>app.button.save</Trans>
                </WppButton>
              </WppGrid>
            </WppGrid>
          </WppGrid>
        </WppGrid>
      )}
      {!initLoading && !isNull(projectQuestionnaireId) && !isNull(questionnaireId) && !isQuestionnaireSelection && (
        <Questionnaire />
      )}
    </>
  )
}

export default ProjectBrief
