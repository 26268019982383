import { StyleSheet } from '@react-pdf/renderer'

import { PDF_FONT_FAMILY_NAME } from 'constants/file'

// Create styles
const styles = StyleSheet.create({
  categoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  formsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  categoryHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: PDF_FONT_FAMILY_NAME,
  },
  fieldHeader: {
    fontSize: 12,
    fontFamily: PDF_FONT_FAMILY_NAME,
  },
  text: {
    fontSize: 12,
    fontFamily: PDF_FONT_FAMILY_NAME,
    fontWeight: 300,
  },
})

export default styles
