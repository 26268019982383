// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dz313{display:flex;flex-direction:row-reverse;gap:8px}.Ga11K strong{font-weight:600}`, "",{"version":3,"sources":["webpack://./src/app/components/common/modals/confirmation/Confirmation.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,0BAAA,CACA,OAAA,CAIA,cACE,eAAA","sourcesContent":[".actions {\n  display: flex;\n  flex-direction: row-reverse;\n  gap: 8px;\n}\n\n.body {\n  strong {\n    font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `dz313`,
	"body": `Ga11K`
};
export default ___CSS_LOADER_EXPORT___;
