import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IOption from 'types/common/IOption'
import IApproval from 'types/review/IApproval'

const defaultProjectBriefState: IProjectBriefState = {
  questionnaire: {
    questionnaireId: null,
    parentQuestionnaireItemId: null,
    projectQuestionnaireId: null,
    questionnaires: [],
    parentQuestionnaires: [],
    parentQuestionnaireId: null,
    approval: null,
  },
  error: {
    serverError: false,
    unauthorizedApp: false,
    unauthorizedQuestionnaire: '',
  },
  initLoading: true,
  loading: false,
  openModal: false,
  disableFormSelection: true,
  disabledQuestionnaireSelection: true,
  showQuestionnaireSelection: false,
  isExistingQuestionnaire: false,
}

export const applicationSlice = createSlice({
  name: 'projectBrief',
  initialState: defaultProjectBriefState,
  reducers: {
    setLoading: (state: IProjectBriefState, action: PayloadAction<boolean>): IProjectBriefState => {
      return {
        ...state,
        loading: action.payload,
      }
    },
    setInitLoading: (state: IProjectBriefState, action: PayloadAction<boolean>): IProjectBriefState => {
      return {
        ...state,
        initLoading: action.payload,
      }
    },
    setParentQuestionnaires: (state: IProjectBriefState, action: PayloadAction<IOption[]>): IProjectBriefState => {
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          parentQuestionnaires: action.payload,
        },
      }
    },
    setParentQuestionnaireItem: (state: IProjectBriefState, action: PayloadAction<string>): IProjectBriefState => {
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          parentQuestionnaireItemId: action.payload,
        },
      }
    },
    setOpenModal: (state: IProjectBriefState, action: PayloadAction<boolean>): IProjectBriefState => {
      return {
        ...state,
        openModal: action.payload,
      }
    },
    setState: (state: IProjectBriefState, action: PayloadAction<IProjectBriefState>): IProjectBriefState => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setAppUnauthorized: (state: IProjectBriefState, action: PayloadAction<boolean>): IProjectBriefState => {
      return {
        ...state,
        error: {
          ...state.error,
          unauthorizedApp: action.payload,
        },
      }
    },
    setApproval: (state: IProjectBriefState, action: PayloadAction<IApproval | null>): IProjectBriefState => {
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          approval: action.payload,
        },
      }
    },
    setQuestionnaireUnauthorized: (state: IProjectBriefState, action: PayloadAction<string>): IProjectBriefState => {
      return {
        ...state,
        error: {
          ...state.error,
          unauthorizedQuestionnaire: action.payload,
        },
      }
    },
    setError: (state: IProjectBriefState, action: PayloadAction<boolean>): IProjectBriefState => {
      return {
        ...state,
        error: {
          ...state.error,
          serverError: action.payload,
        },
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setInitLoading,
  setAppUnauthorized,
  setError,
  setOpenModal,
  setParentQuestionnaireItem,
  setParentQuestionnaires,
  setQuestionnaireUnauthorized,
  setState,
  setApproval,
} = applicationSlice.actions

export default applicationSlice.reducer
