// App actions
export enum APP_ACTIONS {
  SAVE_PROGRESS = 'save_progress',
  SAVE_AND_EXIT = 'save_and_exit',
  EXPORT = 'EXPORT',
  CANCEL = 'cancel',
}

// Stepper action types
export enum STEP_ACTIONS {
  PREVIOUS = 'previous',
  NEXT = 'next',
  STEP = 'step',
}

// Alert messages
export enum TOAST_MESSAGE_TYPES {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFORMATION = 'information',
}
