import { FIELD_TYPES } from 'types/field/enum'

export const DATE_TIME_FORMAT = 'MM/DD/yyyy hh:mm A'

export const TEXT_AREA_CHARACTER_LIMIT = 1000

export const TEXT_AREA_WARNING_THRESHOLD = 900

export const READ_ONLY_FIELDS = [
  FIELD_TYPES.TEXT_INPUT,
  FIELD_TYPES.NUMBER,
  FIELD_TYPES.COUNTER,
  FIELD_TYPES.TEXT_AREA,
  FIELD_TYPES.CURRENCY,
  FIELD_TYPES.SELECT,
  FIELD_TYPES.MARKET,
]
