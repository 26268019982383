import { useSelector } from 'react-redux'

import { WppInlineMessage, WppTooltip } from 'app/components/common'
import styles from 'app/features/review/components/status/Status.module.scss'
import useTerms from 'hooks/useTerms'
import { RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { QUESTIONNAIRE_STATUS } from 'types/review/enum'
import { isEqual } from 'utils/lodash'

/**
 * Shows questionnaire review status if the questionnaire is approved, Send to Review
 */
const ReviewStatus: React.FC = (): React.ReactElement => {
  const termsConfig = useTerms()
  const { app } = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  if (!questionnaire.approval) return <></>
  if (isEqual(questionnaire.approval.status, QUESTIONNAIRE_STATUS.FAILED)) return <></>

  const { issuerFullname, issuerEmail, requestedAt, status, statusStr } = questionnaire.approval

  let tooltipMessage = termsConfig('review_status_tooltip_default_msg', {
    issuerFullname,
    requestedAt,
  })

  if (isEqual(status, QUESTIONNAIRE_STATUS.SUCCEEDED)) {
    tooltipMessage = termsConfig('review_status_tooltip_approved_msg', {
      appName: app!.appName,
      issuerFullname,
      issuerEmail,
      requestedAt,
    })
  }

  return (
    <WppTooltip
      text={tooltipMessage}
      component={
        <WppInlineMessage
          size="s"
          message={termsConfig(statusStr)}
          className={styles.inlineMessage}
          type={isEqual(status, QUESTIONNAIRE_STATUS.SUCCEEDED) ? 'success' : 'information'}
        />
      }
    />
  )
}

export default ReviewStatus
