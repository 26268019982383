import { Link, View, Text } from '@react-pdf/renderer'

import styles from 'app/features/export/components/pdfViewer/field/fieldBuilder/styles'
import FieldChildren from 'app/features/export/components/pdfViewer/field/fieldChildren'
import PdfService from 'app/features/export/services/PdfService'
import FileService from 'services/file/FileService'
import FormFieldValidationService from 'services/formField/FormFieldValidationService'
import IOption from 'types/common/IOption'
import { FIELD_TYPES } from 'types/field/enum'
import IField from 'types/field/IField'
import IFile from 'types/file/IFile'
import { isArray, isEqual, isUndefined, orderBy, toString } from 'utils/lodash'

interface IViewHelperProps {
  field: IField
  option: IOption
  label: string
  homeUrl: string
  projectId: string
}

/**
 * View field component
 * @param {object} props
 * @param {IOption} props.option
 * @param {IField} props.field
 * @param {string} props.label
 * @param {string} props.homeUrl
 * @param {string} props.projectId
 */
export const ViewHelper: React.FC<IViewHelperProps> = ({
  option,
  field,
  label,
  homeUrl,
  projectId,
}: IViewHelperProps): React.ReactElement => (
  <View key={option.id}>
    <Text style={isEqual(field.type, FIELD_TYPES.RADIO) ? styles.text : styles.listItem}>{label}</Text>
    <FieldChildren field={field} homeUrl={homeUrl} projectId={projectId} optionValue={option.id} />
  </View>
)

interface IFieldBuilderProps {
  field: IField
  homeUrl: string
  projectId: string
}

/**
 * Field build component
 * Build field layout in the pdf file based on field type
 * @param {object} props
 * @param {IField} props.field
 * @param {string} props.homeUrl
 * @param {string} props.projectId
 */
const FieldBuilder: React.FC<IFieldBuilderProps> = ({
  field,
  homeUrl,
  projectId,
}: IFieldBuilderProps): React.ReactElement => {
  const { value, isHidden } = field

  if (isHidden) {
    return <></>
  }

  let elementValue = <></>
  switch (field.type) {
    case FIELD_TYPES.FILE_UPLOAD: {
      elementValue = isArray(value) ? (
        <View style={styles.list}>
          {value.map((file: IFile) => (
            <Link
              key={file.id}
              style={styles.listItem}
              src={FileService.buildFilePreviewPath(homeUrl, projectId, file.id)}
            >
              {file.file.name}
            </Link>
          ))}
        </View>
      ) : (
        <></>
      )
      break
    }
    case FIELD_TYPES.TEXT_INPUT:
    case FIELD_TYPES.TEXT_AREA:
    case FIELD_TYPES.NUMBER:
    case FIELD_TYPES.COUNTER:
    case FIELD_TYPES.DATE_RANGE_PICKER:
    case FIELD_TYPES.DATE_PICKER: {
      elementValue = <Text style={styles.text}>{toString(value).split('')}</Text>
      break
    }
    case FIELD_TYPES.CURRENCY: {
      elementValue = (
        <Text style={styles.text}>{value.currencyType ? `${value.currencyAmount} ${value.currencyType}` : ''}</Text>
      )
      break
    }
    case FIELD_TYPES.RADIO: {
      const option: undefined | IOption = PdfService.getRadioValue(field)
      elementValue = !isUndefined(option) ? (
        <ViewHelper homeUrl={homeUrl} projectId={projectId} label={option.label} option={option} field={field} />
      ) : (
        <></>
      )
      break
    }
    case FIELD_TYPES.CHECKBOX: {
      elementValue = isArray(value) ? (
        <View style={styles.list}>
          {orderBy(value, ['id'], 'asc').map((option: IOption) => {
            return (
              <ViewHelper
                key={option.id}
                homeUrl={homeUrl}
                projectId={projectId}
                label={`- ${option.label}`}
                option={option}
                field={field}
              />
            )
          })}
        </View>
      ) : (
        <></>
      )
      break
    }
    case FIELD_TYPES.SELECT:
    case FIELD_TYPES.MARKET:
    case FIELD_TYPES.USER:
    case FIELD_TYPES.USER_MENTION:
    case FIELD_TYPES.AUTOCOMPLETE: {
      elementValue = isArray(value) ? (
        <View style={styles.list}>
          {value.map((val: string) => (
            <View key={val}>
              <Text key={val} style={styles.listItem}>
                - {val}
              </Text>
            </View>
          ))}
        </View>
      ) : (
        <></>
      )
      break
    }
  }

  let isEmptyField = FormFieldValidationService.isEmptyField(field)

  return (
    <View style={styles.fieldContainer}>
      <Text style={styles.fieldHeader}>{field.fieldConfig.text}</Text>
      {isEmptyField ? <Text style={styles.text}>-</Text> : elementValue}
    </View>
  )
}

export default FieldBuilder
