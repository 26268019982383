export enum FIELD_TYPES {
  COUNTER = 'COUNTER',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  TEXT_INPUT = 'TEXT_INPUT',
  TEXT_AREA = 'TEXT_AREA',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  MARKET = 'MARKET',
  USER = 'USER',
  FILE_UPLOAD = 'FILE_UPLOAD',
  SELECT = 'SELECT',
  DATE_PICKER = 'DATE_PICKER',
  DATE_RANGE_PICKER = 'DATE_RANGE_PICKER',
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
  USER_MENTION = 'USER_MENTION',
}

// This is used in Checkbox and Radio button
export enum FIELD_ORIENTATION {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}
