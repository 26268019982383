// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SfAW3{margin-bottom:.5rem;margin-left:.2rem;cursor:pointer}.V3pKd::part(content){width:600px}`, "",{"version":3,"sources":["webpack://./src/app/components/common/modals/help/Help.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CACA,iBAAA,CACA,cAAA,CAIA,sBACE,WAAA","sourcesContent":[".infoIcon {\n  margin-bottom: 0.5rem;\n  margin-left: 0.2rem;\n  cursor: pointer;\n}\n\n.modal {\n  &::part(content) {\n    width: 600px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoIcon": `SfAW3`,
	"modal": `V3pKd`
};
export default ___CSS_LOADER_EXPORT___;
