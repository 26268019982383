import { Text, View } from '@react-pdf/renderer'
import React from 'react'

import Field from 'app/features/export/components/pdfViewer/field'
import styles from 'app/features/export/components/pdfViewer/form/styles'
import IField from 'types/field/IField'
import IForm from 'types/form/IForm'

interface IFormProps {
  form: IForm
  homeUrl: string
  projectId: string
}

/**
 * PDF Form component
 * @param {object} props
 * @param {IForm} props.form
 * @param {string} props.homeUrl
 * @param {string} props.projectId
 */
const Form: React.FC<IFormProps> = ({ form, homeUrl, projectId }: IFormProps): React.ReactElement => {
  return (
    <>
      <View style={styles.formContainer}>
        <Text style={styles.formHeader}>{form.name}</Text>
      </View>
      <View style={styles.fieldsContainer}>
        {form.fields.map((field: IField) => (
          <Field key={field.id} field={field} homeUrl={homeUrl} projectId={projectId} />
        ))}
      </View>
    </>
  )
}

export default Form
