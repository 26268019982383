import React from 'react'

import styles from 'app/components/categorySection/formField/formFieldRadioCheckLabel/FormFieldRadioCheckLabel.module.scss'
import FormFieldTooltip from 'app/components/categorySection/formField/formFieldTooltip'
import { WppTypography } from 'app/components/common'
import IHelpModal from 'types/field/IHelpModal'
import { isEmpty, isEqual } from 'utils/lodash'

interface IFormFieldRadioCheckLabelProps {
  id: string
  text: string
  disabled: boolean
  helpModal?: IHelpModal
  handleChange?: () => void
  tooltip?: string
  description?: string
}

/**
 * Create label for the checkbox and radio button options
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.text
 * @param {boolean} props.disabled
 * @param {IHelpModal} props.helpModal
 * @param {function} props.handleChange
 * @param {string} props.tooltip
 * @param {string} props.description
 */
const FormFieldRadioCheckLabel: React.FC<IFormFieldRadioCheckLabelProps> = (
  props: IFormFieldRadioCheckLabelProps,
): React.ReactElement => {
  const { id, text, disabled, helpModal, tooltip, description, handleChange } = props

  const desc = description ?? ''
  const tooltipText = tooltip ?? ''

  return (
    <>
      {!isEmpty(text) && (
        <div className={styles.container}>
          <WppTypography className={styles.label} aria-disabled={disabled} id={`label-${id}`} type="s-body">
            <div className={styles.labelContainer}>
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
                id={`btn-label-${id}`}
                data-testid={`btn-label-${id}`}
                className={styles.btnLabel}
                onClick={event => {
                  if (isEqual(event.target, event.currentTarget) && !disabled && handleChange) {
                    handleChange()
                  }
                }}
                aria-hidden={true}
              />
              <div className={styles.tooltip}>
                <FormFieldTooltip helpModal={helpModal} tooltip={tooltipText} />
              </div>
            </div>
          </WppTypography>
        </div>
      )}
      {!isEmpty(desc) && (
        <WppTypography className={styles.description} type="s-body">
          <span
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          />
        </WppTypography>
      )}
    </>
  )
}

export default FormFieldRadioCheckLabel
